import React from 'react';

import { InputNumber } from 'primereact/inputnumber';

import Styles from './index.module.scss';
import { Bold } from '../../../CommonStyles.module.scss';

export function MaterialPanel({ onChange, kgInput }) {

  const [urea, setUrea] = React.useState(0)
  const [ammSulfate, setAmmSulfate] = React.useState(0)
  const [ammNitrate, setAmmNitrate] = React.useState(0)
  const [dAP, setDAP] = React.useState(0)
  const [mAP, setMAP] = React.useState(0)
  const [tSP, setTSP] = React.useState(0)
  const [mOP, setMOP] = React.useState(0)
  const [sOP, setSOP] = React.useState(0)
  const [sulpomag, setSulphomag] = React.useState(0)
  const [otherMg, setOtherMg] = React.useState(0)
  const [feCarrier, setFeCarrier] = React.useState(0)
  const [mnCarrier, setMnCarrier] = React.useState(0)
  const [znCarrier, setZnCarrier] = React.useState(0)
  const [bCarrier, setBCarrier] = React.useState(0)
  const [cuCarrier , setCuCarrier] = React.useState(0)
  const [gypsum, setGypsum] = React.useState(0)
  const [filler, setFiller] = React.useState(0)
  const [otherCosts, setOtherCosts] = React.useState(0)

  function setter(setter) {
    return ({ value }) => setter(value)
  }

  function createInput(value, setValue) {
    return (
      <div>
        <InputNumber
          mode="currency"
          currency="USD"
          value={value}
          onChange={setter(setValue)}
        />
      </div>
    )
  }

  function compileData() {
    return {
      urea, ammSulfate, ammNitrate, dAP, mAP, tSP, mOP, sOP, sulpomag, otherMg, feCarrier,
      mnCarrier, znCarrier, cuCarrier, gypsum, filler, otherCosts
    }
  }

  React.useEffect(() => onChange && (void onChange(compileData())), Object.keys(compileData()))

  return (
    <div className={`p-grid ${Styles.NoPadding}`}>
      <div className={`p-col ${Styles.OutputGrid} ${Styles.LabelGrid}`}>
        <span className={Bold}>Material</span>
        {
          [
            'Urea','Amm Sulfate', 'Amm Nitrate', 'DAP', 'MAP', 'TSP', 'MOP', 'SOP', 'Sulpomag',
            'Other Mg', 'Fe carrier', 'Mn carrier', 'Zn carrier', 'B carrier', 'Cu carrier ',
            'Gypsum', 'Filler', 'Other costs'
          ].map(it => <div>{it}</div>)
        }
      </div>
      <div className="p-col" className={Styles.InputGrid}>
        <span className={Bold}>Cost per Tonne</span>
        {
          [
            [urea, setUrea],
            [ammSulfate, setAmmSulfate],
            [ammNitrate, setAmmNitrate],
            [dAP, setDAP],
            [mAP, setMAP],
            [tSP, setTSP],
            [mOP, setMOP],
            [sOP, setSOP],
            [sulpomag, setSulphomag],
            [otherMg, setOtherMg],
            [feCarrier, setFeCarrier],
            [mnCarrier, setMnCarrier],
            [znCarrier, setZnCarrier],
            [bCarrier, setBCarrier],
            [cuCarrier , setCuCarrier],
            [gypsum, setGypsum],
            [filler, setFiller],
            [otherCosts, setOtherCosts]
          ].map(([ value, setter ]) => createInput(value, setter))
        }
      </div>
      <div className={`p-col ${Styles.OutputGrid}`}>
        <span className={Bold}>Amount</span>
        {
          [
            kgInput.urea,
            kgInput.ammSulfate,
            kgInput.ammNitrate,
            kgInput.dAP,
            kgInput.mAP,
            kgInput.tSP,
            kgInput.mOP,
            kgInput.sOP,
            kgInput.sulpomag,
            kgInput.otherMg,
            kgInput.feCarrier,
            kgInput.mnCarrier,
            kgInput.znCarrier,
            kgInput.bCarrier,
            kgInput.cuCarrier,
            kgInput.gypsum,
            kgInput.filler,
          ].map(it => <div>{it || 0} Kg</div>)
        }
      </div>
      <div className={`p-col ${Styles.OutputGrid}`}>
        <span className={Bold}>Cost of Material in Mixture</span>
        {
          [
            urea * kgInput.urea || 0,
            ammSulfate * kgInput.ammSulfate || 0,
            ammNitrate * kgInput.ammNitrate || 0,
            dAP * kgInput.dAP || 0,
            mAP * kgInput.mAP || 0,
            tSP * kgInput.tSP || 0,
            mOP * kgInput.mOP || 0,
            sOP * kgInput.sOP || 0,
            sulpomag * kgInput.sulpomag || 0,
            otherMg * kgInput.otherMg || 0,
            feCarrier * kgInput.feCarrier || 0,
            mnCarrier * kgInput.mnCarrier || 0,
            znCarrier * kgInput.znCarrier || 0,
            bCarrier * kgInput.bCarrier || 0,
            cuCarrier * kgInput.cuCarrier || 0,
            gypsum * kgInput.gypsum || 0,
            filler * kgInput.filler || 0,
            otherCosts * 1000
          ].map(it => <div>${it.value.toFixed(2)}</div>)
        }

      </div>
    </div>
  );
}
