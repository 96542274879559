import React from 'react';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { auth, mkUserDocument } from '../../../services/firebase';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../const';

import Styles from './RegisterScreen.module.scss';
import CommonStyles from '../../CommonStyles.module.scss';

export default function RegisterScreen() {

  const [triedOnce, setTriedOnce] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const [email, setEmail] = React.useState('')
  const [pwd, setPwd] = React.useState('')
  /*const [confirmPwd, setConfirmPwd] = React.useState('')*/
  const [displayName, setDisplayName] = React.useState('')

  function handleSubmit() {
    setTriedOnce(true)
    if (EMAIL_REGEX.test(email) /*&& pwd === confirmPwd*/ && displayName.length >= 3) {
      if (PASSWORD_REGEX.test(pwd)) {
        setLoading(true)
        setError('')
        auth.createUserWithEmailAndPassword(email, pwd)
          .then(({ user }) => mkUserDocument(user, { displayName }))
          .then(() => auth.currentUser.sendEmailVerification())
          .then(() => {
            setEmailSent(true)
            setLoading(false)
          }).catch((e) => {
            console.error(e)
            setLoading(false)
            setError(e.message)
          })
      } else {
        setError('Please use a password with at least one number, one letter and 8 digits long')
      }
    }
  }

  const renderForm = () => <>
    <img className={Styles.logo} src="./logo.png"/>
    <p className={Styles.Title}>Fertilizer Cost Simulator</p>
    <p className={Styles.Subtitle}>Taking knowledge from Port to Farm</p>
    <div className={Styles.FormField}>
      <span className="p-float-label">
        <InputText
          name="email"
          type="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          required
        />
        <label htmlFor="email">E-mail Address</label>
      </span>
      { triedOnce && !email && <span className={CommonStyles.RedText}>Please provide an e-mail address</span> }
    </div>
    <div className={Styles.FormField}>
      <span className="p-float-label">
        <InputText
          name="full-name"
          type="text"
          value={displayName}
          onChange={({ target }) => setDisplayName(target.value)}
          required
        />
        <label htmlFor="full-name">Full Name</label>
      </span>
      { email && displayName.length < 3 && <span className={CommonStyles.RedText}>Please enter your full name, at least 3 characters long</span> }
    </div>
    <div className={Styles.FormField}>
    <span className="p-float-label">
      <Password className={Styles.passwordPadding} required  feedback={false} value={pwd} onChange={(e) => setPwd(e.target.value)} toggleMask />
        <label htmlFor="pwd">Password</label>
      </span>
      <p className={Styles.pswReqs}>• Your password must have at least one number, one letter and 8 digits</p>
    </div>
    <div className={Styles.FormField}>
      {/*<span className="p-float-label">
        <InputText
          name="confirm-pwd"
          type="password"
          value={confirmPwd}
          onChange={({ target }) => setConfirmPwd(target.value)}
          required
        />
        <label htmlFor="confirm-pwd">Confirm Password</label>
      </span>*/}
      {/*{ pwd && pwd !== confirmPwd && <span className={CommonStyles.RedText}>The passwords doesn't match</span>} */}
    </div>
    <button className="btn btn--primary mt--20" label="Send" onClick={handleSubmit}>Register</button>
    <p className={Styles.DontAccountSpan}>Already got an account? <Link to="/login">Sign In</Link></p>
  </>

  const renderLoading = () => <ProgressSpinner/>

  return (
    <main className={Styles.RegisterScreen}>
      <Card 
      className={classNames(Styles.RegisterForm, error ? Styles.Error : emailSent ? Styles.Success : '')}
      subTitle={error || (emailSent ? 'Please follow the link sent to your e-mail inbox' : '')}
    >
      { loading ? renderLoading() : renderForm() }
    </Card>
    </main>
    
  )
}
