import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { usePlacesOfBlending } from '../../../hooks';
import ItemWithCountryFlag from '../ItemWithCountryFlag';
import {FormatOptions, formatTown} from "../../../formatter";

export default ({ id, value, onChange, allowNone, allowClear = false }) => {
  const [places] = usePlacesOfBlending();

  const items = [
    ...(allowNone ? [{ value: null, label: 'At Origin or None' }] : []),
    ...places.map(it => ({
      value: it.id,
      label: formatTown(it, [FormatOptions.COUNTRY_ALPHA]),
      ...it
    })).sort((a, b) => a.label.localeCompare(b.label))
  ]

  function createItemTemplate({ name, country, label }) {
    return name && country ? (
      <ItemWithCountryFlag
        display={formatTown({ name, country }, [FormatOptions.COUNTRY_ALPHA])}
        alphaCode={country?.alphaCode}
        countryDisplayName={country?.displayName}
      />
    ) : label
  }

    return (
        <Dropdown
            id={id}
            value={value}
            options={items}
            appendTo={document.body}
            placeholder="Select a place to blend"
            onChange={({ value }) => onChange(value)}
            style={{ width: '100%' }}
            itemTemplate={createItemTemplate}
            filterBy="label"
            showClear={allowClear}
            filter
        />
    )
}
