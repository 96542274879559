import React from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import toast, { Toaster } from "react-hot-toast";
import { auth } from "../../../services/firebase";
import { EMAIL_REGEX } from "../../../const";
import Styles from "./ResetPassword.module.scss";
import { Link } from "react-router-dom";

export default function ResetPassword() {
  const [email, setEmail] = React.useState("");
  const [emailSent, setEmailSent] = React.useState(false);
  const [error, setError] = React.useState("");

  function handleSubmit() {
    if (EMAIL_REGEX.test(email.toLowerCase())) {
      const promise = auth.sendPasswordResetEmail(email);

      toast.promise(promise, {
        loading: "Sending email...",
        success: "Email sent successfully!",
        error: "Error sending email",
      });

      promise
        .then(() => {
          setEmailSent(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      toast.error("Please, insert your email.");
    }
  }

  const header = (
    <div className={Styles.Header}>
      <div className={Styles.logo}>
        <img src="./logo.png" alt="Logo" />
      </div>
      <h1 className={Styles.Title}>Forgot your password?</h1>
      <p className={Styles.Subtitle}>
        Insert your e-mail bellow and we will send you a new recovery password.
      </p>
    </div>
  );
  const footer = (
    <div>
      <button
        className="btn btn--primary mt-20"
        label="Send me a recovery e-mail"
        dusable
        onClick={handleSubmit}
      >
        Send me a recovery E-mail
      </button>
      <div className="afterBefore orGoogle">Or</div>
      <br />
      <Link to="/register">Sign Up</Link>
    </div>
  );

  /*const subtitle = error || (emailSent ? notify : '')*/

  return (
    <>
      <Toaster />
      <main className={Styles.ResetPasswordScreen}>
        <div className={Styles.MainTest}>
          <Card
            header={header}
            className={classNames(
              Styles.ResetPasswordForm,
              error ? Styles.Error : emailSent ? Styles.Success : ""
            )}
            footer={footer}
            /*subTitle={subtitle}*/
          >
            <div className={Styles.FormField}>
              <span className="p-float-label margin--input">
                <InputText
                  name="email"
                  type="email"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  required
                />
                <label htmlFor="email">E-mail address</label>
              </span>
            </div>
          </Card>
          <Link to="/login">
            <button className="btn btn--secondary" id={Styles.BackToLogin}>
              ← Go back to Login
            </button>
          </Link>
        </div>
      </main>
    </>
  );
}
