import React from "react";
import { ApiContext } from "../providers/ApiProvider";
import useErrorHandler from "./useErrorHandler";

export default (id) => {
  const api = React.useContext(ApiContext);
  //const errorHandler = useErrorHandler('Could not load the existing Places of Blending');

  const [data, setData] = React.useState(
    typeof id === "number" ? undefined : []
  );
  const [loading, setLoading] = React.useState(false);

  function loader() {
    setLoading(true);
    const provider = typeof id === "number" ? api.getTown : api.getTowns;

    provider(id)
      .then(({ data }) => {
        if (id) {
          if (data !== undefined) {
            setData(data.isPlaceOfBlending === true ? data : undefined);
          } else {
            setData(data);
          }
        } else {
          setData(data.filter((it) => it.isPlaceOfBlending));
        }
        setLoading(false);
      })
      .catch((error) => {
        //errorHandler(error);
        window.location.reload(); // Recarrega a página se ocorrer um erro
      });
  }

  React.useEffect(loader, [id]);

  return [data, loading, loader];
};
