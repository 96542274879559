import React from 'react';
import { ApiContext } from '../../../../providers/ApiProvider';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { Column } from 'primereact/column';

import { Dialog } from 'primereact/dialog';
import GenericMaintenanceScreen from './GenericMaintanceScreen';
import FloatLabel from '../../../utils/FloatLabel';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import PortSelector from '../../../utils/selectors/PortSelector';
import PassageMode from '../../../utils/selectors/PassageModeSelector';
import { InputNumber } from 'primereact/inputnumber';

import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
import XIcon from '../../../../assets/images/XIcon.svg'

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext)
  const notification = React.useContext(NotificationContext)
  const errorHandler = useErrorHandler('Something went wrong on managing the data')

  const [loading, setLoading] = React.useState(false)

  const [port, setPort] = React.useState()
  const [mode, setMode] = React.useState()
  const [min, setMin] = React.useState(0)
  const [max, setMax] = React.useState(0)

  React.useEffect(refresh, [id])

  function refresh() {
    setLoading(true)
    if (id) {
      api.getPortCharge(id)
        .then(({ data }) => {
          setPort(data.port.id)
          setMode(data.mode.id)
          setMin(data.min)
          setMax(data.max)
          setLoading(false)
        }).catch(errorHandler)
    } else {
      setPort(undefined)
      setMode(undefined)
      setMin(0)
      setMax(0)
      setLoading(false)
    }
  }

  function finish() {
    toast.success('Charge saved successfully')
    onHide()
    notify()
  }

  function send() {
    if (port && mode) {
      const payload = { port, mode, min, max }
      setLoading(true)
      if (id) {
        api.editPortCharge(id, payload).then(finish).catch(errorHandler)
      } else {
        api.addPortCharge(payload).then(finish).catch(errorHandler)
      }
    }
  }

  function finish() {
    toast.success('Charge saved successfully')
    onHide()
    notify()
  }

  function deletedToast() {
    toast.success('Charge deleted successfully')
    onHide()
    notify()
  }

  function handleDelete({ id, t }) {
    api.deletePortCharge(id).then(deletedToast).catch(errorHandler)
    toast.dismiss(t.id)
  }

  function askDelete() {
    toast((t) => (
      <div className="deleteToastAdm">
        <h1> Are you sure you want to delete?</h1>
        <p>This is an one-way action!</p>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <button className="btn btn--secondary" onClick={() => toast.dismiss(t.id)}>
            Cancel
          </button>
          <button style={{ marginLeft: '5px' }} className="btn btn--alert center" onClick={() => handleDelete({ id, t })}>
            Delete
          </button>
        </div>
      </div>
    ))
  }

  const header =
    <>
      <div style={{ position: 'relative' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ margin: '0' }}>{id ? `Editing` : 'Creating'}</h1>
          <button className="xIconButton center" onClick={onHide}>
            <img src={XIcon} alt="" />
          </button>
        </div>
        <p>{id ? `Select the options below to edit the Port ` : 'Select the options below to create the Port'}</p>
      </div>
    </>


  const body = <>
    <div className="p-grid">
      <div className="firstFloatLabel">
        <div className="p-col">
          <label className="selectLabelAdm" htmlFor="port">Port:</label><br />
          <PortSelector id="port" value={port} onChange={setPort} />
        </div>
      </div>
      <div className="firstFloatLabel">
        <div className="p-col">
          <label className="selectLabelAdm" htmlFor="mode">Mode:</label><br />
          <PassageMode id="mode" value={mode} onChange={setMode} />
        </div>
      </div>
    </div>
    <div className="p-grid">
      <div className="p-col">
        <div className="firstFloatLabel">
          <FloatLabel id="min" label="Min Price">
            <InputNumber
              mode="currency"
              currency="USD"
              value={min}
              onValueChange={e => setMin(e.value)}
            />
          </FloatLabel>
        </div>
      </div>
      <div className="p-col">
        <FloatLabel id="max" label="Max Price">
          <InputNumber
            mode="currency"
            currency="USD"
            value={max}
            onValueChange={e => setMax(e.value)}
          />
        </FloatLabel>
      </div>
    </div>
  </>

  const footer = <>
    {id && !loading ? (
      <>
        <div style={{ width: '100%', display: 'flex' }}>
          <button className="btn btn--alert center" onClick={askDelete}>Delete</button>
          <button className="btn btn--primary" onClick={send}>Save</button>
        </div>
      </>
    ) :
      <>

        {!loading && <button className="btn btn--primary" onClick={send}>Create</button>}
      </>
    }

  </>

  return (
    <Dialog
      className="modalAdmWrapper"
      onHide={onHide}
      visible={visible}
      header={header}
      footer={footer}
      draggable={false}
    >
      {loading ?
        <div className="dfCenter">
          <ReactLoading type={'spinningBubbles'} color={'#31c5f4'} height={'40px'} width={'40px'} />
        </div> : body}
    </Dialog>
  )
}

export default function PortChargesMaintenance() {

  const api = React.useContext(ApiContext)

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  )

  function filterData(data, filterTable) {
    const dataFiltered = filterTable.length > 0 ? data.filter(e =>
      (e.mode &&
        e.mode.name
          .toLowerCase()
          .replace(/[\s-]/g, '')
          .includes(filterTable.replace(/[\s-]/g, '').toLowerCase())) ||
      (e.port &&
        e.port.name
          .toLowerCase()
          .replace(/[\s-]/g, '')
          .includes(filterTable.replace(/[\s-]/g, '').toLowerCase())) ||
      (e.port.country &&
        e.port.country.displayName
          .toLowerCase()
          .replace(/[\s-]/g, '')
          .includes(filterTable.replace(/[\s-]/g, '').toLowerCase())) ||
      (e.port.country &&
        e.port.country.alphaCode
          .toLowerCase()
          .replace(/[\s-]/g, '')
          .includes(filterTable.replace(/[\s-]/g, '').toLowerCase()))
    ) : data

    return dataFiltered
  }

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getPortCharges}
      renderEditDialog={dialog}
      filterData={filterData}
    >
      <Column field="id" header="Id" sortable />
      <Column field="min" header="Min Price" sortable />
      <Column field="max" header="Max Price" sortable />
      <Column
        field="mode.name"
        header="Passage Mode"
        body={it => it?.mode?.name}
        sortable
      />
      <Column
        field="port.name"
        header="Port"
        body={({ port }) => `${port?.name} (${port?.country?.alphaCode})`}
        sortable
      />
    </GenericMaintenanceScreen>
  )
}
