import React from "react";

import { Dropdown } from "primereact/dropdown";
import ItemWithCountryFlag from "../ItemWithCountryFlag";
import { usePorts } from "../../../hooks";
import { FormatOptions, formatPort } from "../../../formatter";

export default ({ id, allowNone, value, onChange }) => {
  const [ports] = usePorts();

  let options = ports
    .map((it) => ({
      ...it,
      value: it.id,
      label: display(it),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  if (allowNone) {
    options = [{ value: null, label: "None (Local)" }, ...options];
  }

  function display(port) {
    return !port.name || !port.country
      ? "None (Local)"
      : formatPort(port, [FormatOptions.COUNTRY_ALPHA]);
  }

  function itemTemplate(it) {
    return !it.value ? (
      display(it)
    ) : (
      <ItemWithCountryFlag
        alphaCode={it.country?.alphaCode}
        display={display(it)}
      />
    );
  }

  return (
    <Dropdown
      id={id}
      value={value}
      options={options}
      appendTo={document.body}
      placeholder="Select a departure city"
      //itemTemplate={itemTemplate}
      onChange={({ value }) => onChange(value)}
      style={{ minWidth: "18rem", width: "100%", marginBottom: 10 }}
    />
  );
};
