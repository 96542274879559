import React, { useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { Menubar } from 'primereact/menubar';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { Toaster } from 'react-hot-toast';

import { auth } from '../../../services/firebase';
import FinalDestinationMaintenance
  from './maintenance/FinalDestinationMaintenance';
import PortMaintenance from './maintenance/PortMaintenance';
import ProductTypeMaintenance from './maintenance/ProductTypeMaintenance';
import PassageModeMaintenance from './maintenance/PassageModeMaintenance';
import CountryMaintenance from './maintenance/CountryMaintenance';

import Styles from './index.module.scss';
import TownMaintenance from './maintenance/TownMaintenance';
import PortChargesMaintenance from './maintenance/PortChargesMaintenance';
import LandTransportMaintenance from './maintenance/LandTransportMaintenance';

export default function AdminScreen({ history }) {

  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const [activeAsideMenuLink, setActiveAsideMenuLink] = useState('');
  const [isDesktopMenuOn, setIsDesktopMenuOn] = useState('');

  React.useEffect(() => {
    const location = pathname.substring(path.length + 1).split('/')[0]
    setActiveAsideMenuLink(location)
  }, [pathname])

  function goTo(page) {
    setActiveAsideMenuLink(page)
    history.push(`${path}${page}`)
  }

  const header = (() => {
    const location = pathname.substring(path.length + 1).split('/')[0]
    switch (location) {
      case 'finalDestination': return 'Final Destinations'
      case 'port': return 'Port'
      case 'productType': return 'Product Types'
      case 'passageMode': return 'Passage Modes'
      case 'country': return 'Countries'
      case 'town': return 'Towns'
      case 'portCharge': return 'Port Charges'
      case 'landTransport': return 'Land Transport'
      default: return ''
    }
  })()

  useEffect(() => {
    if (window.innerWidth > 959) {
      setIsDesktopMenuOn(true)
    }

    else {
      setIsDesktopMenuOn(false)
    }
  }, [])

  const menuModel = [

    {
      label: isDesktopMenuOn ? 'Countries' : null,
      icon: 'fa fa-flag',
      command: () => goTo('/country'),
      className: activeAsideMenuLink === 'country' && ('activeMenuLinkAside')
    },
    {
      label: isDesktopMenuOn ? 'Towns' : null,
      icon: 'fa fa-map-marker',
      command: () => goTo('/town'),
      className: activeAsideMenuLink === 'town' && ('activeMenuLinkAside')
    },
    {
      label: isDesktopMenuOn ? 'Final Destinations' : null,
      icon: 'fa fa-map-pin',
      command: () => goTo('/finalDestination'),
      className: activeAsideMenuLink === 'finalDestination' && ('activeMenuLinkAside')
    },
    {
      label: isDesktopMenuOn ? 'Ports' : null,
      icon: 'fa fa-ship',
      command: () => goTo('/port'),
      className: activeAsideMenuLink === 'port' && ('activeMenuLinkAside')
    },
    {
      label: isDesktopMenuOn ? 'Product Types' : null,
      icon: 'fa fa-list',
      command: () => goTo('/productType'),
      className: activeAsideMenuLink === 'productType' && ('activeMenuLinkAside')
    },
    {
      label: isDesktopMenuOn ? 'Passage Modes' : null,
      icon: 'fa fa-tags',
      command: () => goTo('/passageMode'),
      className: activeAsideMenuLink === 'passageMode' && ('activeMenuLinkAside')
    },
    {
      label: isDesktopMenuOn ? 'Port Charges' : null,
      icon: 'fa fa-file-invoice',
      command: () => goTo('/portCharge'),
      className: activeAsideMenuLink === 'portCharge' && ('activeMenuLinkAside')
    },
    {
      label: isDesktopMenuOn ? 'Land Transports' : null,
      icon: 'fa fa fa-truck',
      command: () => goTo('/landTransport'),
      className: activeAsideMenuLink === 'landTransport' && ('activeMenuLinkAside')
    }
  ]

  return (
    <>
      <Toaster />
      <header className={Styles.Header}>
        <Menubar
          start={
            <img src="/logo.png" className={Styles.logoNavbar} onClick={() => history.push(`/my/home`)} />}
          end={
            <span className={Styles.SignOut} onClick={() => auth.signOut()}>
              Sign Out
            </span>}
          model={[
            {
              label: 'Home',
              icon: 'fa fa-home',
              url: '/my/home'
            },
            {
              label: 'Formulas',
              url: '/my/formula',
            },
            {
              label: 'Compare Formulas',
              url: '/my/formulas/compare',
            },
            {
              label: 'About Us',
              url: '/my/about',
            }
          ]}
        />
      </header>
      <main className={Styles.AdminPage}>
        <aside className={Styles.MenuWrapper}>
          <Menu model={menuModel} className={Styles.Menu} />
        </aside>
        <section className={Styles.PageWrapper}>

          {header && (
            <h1>{header}</h1>
          )}
          <section className={Styles.Page}>
            <Switch>
              <Route path={`${path}/landTransport`} component={LandTransportMaintenance} />
              <Route path={`${path}/portCharge`} component={PortChargesMaintenance} />
              <Route path={`${path}/finalDestination`} component={FinalDestinationMaintenance} />
              <Route path={`${path}/port`} component={PortMaintenance} />
              <Route path={`${path}/productType`} component={ProductTypeMaintenance} />
              <Route path={`${path}/passageMode`} component={PassageModeMaintenance} />
              <Route path={`${path}/country`} component={CountryMaintenance} />
              <Route path={`${path}/town`} component={TownMaintenance} />
            </Switch>
          </section>
        </section>
      </main>
    </>
  )
}
