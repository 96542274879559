import React from "react";

import { ApiContext } from "../providers/ApiProvider";
import { UserContext } from "../providers/UserProvider";
import { IS_ADMIN } from "../data";

export default () => {
  const api = React.useContext(ApiContext);
  const user = React.useContext(UserContext);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const email = user?.email;

  React.useEffect(() => void IS_ADMIN.get(api).then(setIsAdmin), [email, api]);

  return isAdmin;
};
