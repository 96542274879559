import React from 'react';
import { NotificationContext } from '../providers/NotificationProvider';
import toast, {Toaster} from 'react-hot-toast'

export default (message, details) => {
  const notifications = React.useContext(NotificationContext)
  return (e) => {
    console.error(e)
    try {
      if (notifications) {
        toast.error(message || 'An error occurred', details || 'Please try again later')
      }
    } catch (e2) {
      console.error(e2)
    }
  }
}
