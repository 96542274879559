import React from "react";

import { InputNumber } from "primereact/inputnumber";
import PassageModeSelector from "../../../../utils/selectors/PassageModeSelector";
import PortSelector from "../../../../utils/selectors/PortSelector";
import Tooltip from "rc-tooltip";

import Styles from "./index.module.scss";
import TransitModeSelector from "../../../../utils/selectors/TransitModeSelector";
import toast, { Toaster } from "react-hot-toast";
import { useProductTypes } from "../../../../../hooks";

export function EditProductForm({
  data,
  onChange,
  portPreferences,
  dropdowns,
}) {
  const {
    id,
    transit,
    maxFob,
    seaFreight,
    storageDuration,
    passageMode,
    storageCost,
    loanCashoutDuration,
    bankInterestRate,
    demurrageDuration,
    demurragePrice,
  } = data;

  const productTypes = useProductTypes();

  React.useEffect(() => {
    setter({ port: portPreferences });
  }, [portPreferences]);

  React.useEffect(() => {
    if (dropdowns) {
      const { dropDownSelectedDestination, dropDownSelectedPOE } = dropdowns;
      if (
        dropDownSelectedPOE?.country?.alphaCode !==
        dropDownSelectedDestination?.town?.country?.alphaCode
      ) {
        setter({ transit: "DIRECT" });
      } else {
        setter({ transit: "NONE" });
      }
    }
  }, [dropdowns]);

  function setter(dataChanged) {
    onChange({ ...data, ...dataChanged });
  }

  function getNewCosts(id) {
    productTypes.map((e) => {
      if (e.id == id) {
        setter({ maxFob: e.maxFob });
      }
    });
    toast.success(`The costs of FOB Prices have been updated.`);
  }

  function askGetNewCosts(id) {
    toast((t) => (
      <div className="deleteToastAdm">
        <h1 style={{ fontSize: 18 }}>
          This will overwrite your changes, do you want to proceed?
        </h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(t.id)}
          >
            Cancel
          </button>
          <button
            style={{ marginLeft: "5px" }}
            className="btn btn--primary center"
            onClick={() => {
              toast.dismiss(t.id);
              getNewCosts(id);
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    ));
  }

  return (
    <div className={Styles.Form}>
      <div className="p-fluid p-formgrid p-grid">
        <div className={`p-col ${Styles.Card}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>FOB Prices</h1>

            <Tooltip placement="top" trigger="hover" overlay={"Get New Costs"}>
              <span
                className="noPrint"
                onClick={() => askGetNewCosts(id)}
                style={{ cursor: "pointer" }}
              >
                <i
                  className="pi pi-refresh"
                  style={{
                    color: "#000",
                    fontSize: "1rem",
                    marginLeft: 5,
                  }}
                />
              </span>
            </Tooltip>
          </div>

          <label htmlFor="maxFob">FOB Price at Origin</label>
          <InputNumber
            id="maxFob"
            mode="currency"
            currency="USD"
            value={maxFob}
            onValueChange={(e) => setter({ maxFob: e.value })}
          />
        </div>
        <div className={`p-col ${Styles.Card}`}>
          <h1>CIF Prices</h1>
          <div style={{ display: "none" }}>
            <label htmlFor="port">Port of Origin</label>
            <PortSelector
              id="port"
              value={portPreferences ? portPreferences : ""}
              onChange={(port) => setter({ port })}
              allowNone
            />
          </div>
          <div className="inputCard">
            <label htmlFor="seaFreight">Sea Freight</label>
            <InputNumber
              id="seaFreight"
              mode="currency"
              currency="USD"
              suffix={" / MT"}
              value={seaFreight}
              onValueChange={(e) => setter({ seaFreight: e.value })}
            />
          </div>
        </div>
        <div className={`p-col ${Styles.Card}`}>
          <h1>Transport Mode</h1>
          <div>
            <label htmlFor="passageMode">Passage Mode</label>
            <PassageModeSelector
              id="passageMode"
              value={passageMode}
              onChange={(e) => setter({ passageMode: e })}
            />
          </div>
          <div className="inputCard">
            <label htmlFor="transit">Transit Mode</label>
            <TransitModeSelector
              id="transit"
              value={transit}
              onChange={(transit) => setter({ transit })}
            />
          </div>
        </div>
      </div>
      <div className="p-fluid p-formgrid p-grid">
        <div className={`p-col ${Styles.Card}`}>
          <h1>Finance</h1>
          <div>
            <label htmlFor="loalCashoutDuration">Loan/Cash out duration</label>
            <InputNumber
              id="loanCashoutDuration"
              min={0}
              value={loanCashoutDuration}
              onValueChange={(e) => setter({ loanCashoutDuration: e.value })}
              suffix={" Month(s)"}
            />
          </div>
          <div className="inputCard">
            <label htmlFor="bankInterestRate">Bank Interest Rate</label>
            <InputNumber
              id="bankInterestRate"
              value={bankInterestRate}
              onValueChange={(e) => setter({ bankInterestRate: e.value })}
              min={0}
              max={100}
              suffix="%"
            />
          </div>
        </div>
        <div className={`p-col ${Styles.Card}`}>
          <h1>Storage</h1>
          <div>
            <label htmlFor="storageDuration">Storage Duration</label>
            <InputNumber
              id="storageDuration"
              value={storageDuration}
              onValueChange={(e) => setter({ storageDuration: e.value })}
              min={0}
              suffix={" Month(s)"}
            />
          </div>
          <div className="inputCard">
            <label htmlFor="storageCost">Storage Cost</label>
            <InputNumber
              id="storageCost"
              mode="currency"
              currency="USD"
              suffix={" / MT / Month"}
              value={storageCost}
              onValueChange={(e) => setter({ storageCost: e.value })}
            />
          </div>
        </div>
        <div className={`p-col ${Styles.Card}`}>
          <h1>Demurrage</h1>
          <div>
            <label htmlFor="demurrageDuration">Demurrage Duration</label>
            <InputNumber
              id="demurrageDuration"
              suffix={" Day(s)"}
              value={demurrageDuration}
              onValueChange={(e) => setter({ demurrageDuration: e.value })}
            />
          </div>
          <div className="inputCard">
            <label htmlFor="demurragePrice">Demurrage Price</label>
            <InputNumber
              id="demurragePrice"
              mode="currency"
              currency="USD"
              min={0}
              value={demurragePrice}
              onValueChange={(e) => setter({ demurragePrice: e.value })}
              suffix={" / MT / Day"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
