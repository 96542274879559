import React from "react";
import firebase from "firebase/app";
import useErrorHandler from "./useErrorHandler";
import { useAsyncMemo } from "use-async-memo";
import { distinctBy, useDeepCompareMemoized } from "../utils";
import useOwnFormulas from "./useOwnFormulas";
import usePublicFormulas from "./usePublicFormulas";
import { OWN_FORMULAS, PUBLIC_FORMULAS } from "../data";

const firestore = firebase.firestore();
const collection = firestore.collection("formulas");

const QueryMode = {
  BY_ID: 0,
  ALL_OWN: 1,
  ALL_PUBLIC: 2,
};

export const byId = (id) => ({ mode: QueryMode.BY_ID, id });
export const allOwn = (queryString = "") => ({
  mode: QueryMode.ALL_OWN,
  queryString,
});
export const allPublic = (email = "", queryString = "") => ({
  mode: QueryMode.ALL_PUBLIC,
  email,
  queryString,
});

/* eslint-disable react-hooks/rules-of-hooks */
export const queryRemoteFormulas = (queryModes = [], forceArrayReturn) => {
  const ownFormulas = useOwnFormulas(
    queryModes.some((it) => it.mode === QueryMode.ALL_OWN)
  );
  const publicFormulas = usePublicFormulas(
    queryModes.some((it) => it.mode === QueryMode.ALL_PUBLIC)
  );

  const errorHandler = useErrorHandler("Could not load your saved formulas");

  const documentsDependencies = useDeepCompareMemoized([
    ownFormulas,
    publicFormulas,
    queryModes,
    ownFormulas,
  ]);

  const documents = useAsyncMemo(
    async () => {
      try {
        const queries = queryModes
          .filter(
            (params) =>
              !(params.mode === QueryMode.BY_ID && params.id === undefined)
          )
          .map((params) => {
            function createQuery() {
              const { mode, id } = params;
              switch (mode) {
                case QueryMode.BY_ID:
                  return async () => {
                    if (OWN_FORMULAS.isPresent()) {
                      const ownFormulasCache = await OWN_FORMULAS.get();
                      const cached = ownFormulasCache.find(
                        (it) => it.id === id
                      );
                      if (cached) {
                        console.debug(
                          `[DEBUG CACHE/FIRESTORE]  Formula ${id} found on user cache`
                        );
                        return [cached];
                      }
                    }

                    if (PUBLIC_FORMULAS.isPresent()) {
                      const publicFormulasCache = await PUBLIC_FORMULAS.get();
                      const cached = publicFormulasCache.find(
                        (it) => it.id === id
                      );
                      if (cached) {
                        console.debug(
                          `[DEBUG CACHE/FIRESTORE] Formula ${id} found on public cache`
                        );
                        return [cached];
                      }
                    }

                    console.debug(
                      `[DEBUG CACHE/FIRESTORE] Formula ${id} not found in caches, retrieving...`
                    );
                    const dataFromFirestore = await collection.doc(id).get();
                    return [
                      { id: dataFromFirestore.id, ...dataFromFirestore.data() },
                    ];
                  };
                case QueryMode.ALL_OWN:
                  return async () => ownFormulas;
                case QueryMode.ALL_PUBLIC:
                  return async () => publicFormulas;
                default:
                  throw new Error(`Invalid query mode ${mode}`);
              }
            }

            return async () => ({ params, data: await createQuery()() });
          });

        const data = await Promise.all(queries.map((it) => it()));
        const reducedData = data.reduce(
          (buff, current) => [
            ...buff,
            ...current.data.map((it) => ({ params: current.params, data: it })),
          ],
          []
        );

        return distinctBy(reducedData, (it) => it.data.id)
          .filter(({ data, params }) => {
            return !params.queryString
              ? true
              : data.email.includes(params.queryString) ||
                  data.name.includes(params.queryString);
          })
          .map((it) => it.data);
      } catch (e) {
        //errorHandler(e)
        window.location.reload();
        return [];
      }
    },
    documentsDependencies,
    []
  );

  const toReturnDeps = useDeepCompareMemoized([
    ownFormulas,
    publicFormulas,
    queryModes,
    documents,
  ]);
  const toReturn = React.useMemo(() => {
    if (
      !forceArrayReturn &&
      queryModes.length === 1 &&
      queryModes[0].mode === QueryMode.BY_ID
    ) {
      return documents[0];
    }
    return documents;
  }, toReturnDeps);

  return toReturn;
};
/* eslint-enable react-hooks/rules-of-hooks */
