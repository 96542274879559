import Tooltip from 'rc-tooltip';
import React, { useState, useEffect, createRef } from 'react';
import ArrowDownIcon from '../../../src/assets/images/ArrowDownIconWhite.png'
import InfoIcon from '../../../src/assets/images/infoIcon.svg'

function dropdown(p, elementRef) {
    var d = ["block", "none"];
    elementRef.current.style.display = d[p];

    var t = ["0", "0  , -10px"];

    setTimeout(function () {
      if(elementRef && elementRef.current && elementRef.current.style !== null) {
        elementRef.current.style.transform = 'translate(' + t[p] + ')'
      }
    }, 0)
}

export default function SelectDropdown({ myLabel, myId, inputName, myPlaceholder, selectOptions, completeOptions, isInfo, onSelectValue, setValue, open, value }) {
  function category(selectedValue, inputRef) {
    if (selectedValue != 'No results found') {
      inputRef.current.value = selectedValue;
    }

    onSelectValue && completeOptions && onSelectValue(completeOptions.find(option => {
      return myId !== 'searchbarFD' ? `${option.name} (${option.country.alphaCode})` === selectedValue : `${option.town.name} (${option.town.country.alphaCode})` === selectedValue
    }), setValue, myId);
  }

  const myDropdown = createRef();
  const myInput = createRef();
  const [query, setQuery] = useState([]);

  useEffect(() => {
    setQuery(selectOptions)
  }, [selectOptions]);

  const handleFilter = (search) => {

    const filteredList = selectOptions.filter(
      (option) =>
        option
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) >= 0
    );

    if (filteredList.length <= 0) {
      filteredList.push('No results found');
    }

    setQuery(filteredList);
  }

  useEffect(() => {
    open && setTimeout(function () {
      document.getElementById(myId).focus()
    }, 200)
  }, [open])

  useEffect(() => {
    if(typeof value === "object"){
      document.getElementById(myId).value = 
        myId !== 'searchbarFD' ? 
          value.name + ` (${value.country.alphaCode})`
        : value.town.name + ` (${value.town.country.alphaCode})`
    }
  }, [value])

  return (
    <div className="dropdownSelect">
      <div className="placeOfProcessingHeader">
        <p>{myLabel}</p>
        {isInfo && (
          <Tooltip placement='top' trigger='hover' overlay={isInfo}>
            <img src={InfoIcon}
            />
          </Tooltip>
        )}
      </div>
      <div>
        <input
          ref={myInput}
          id={myId}
          autoComplete="off"
          spellCheck="false"
          className="dropdownInput"
          type="text"
          name={inputName}
          placeholder={myPlaceholder}
          onChange={(e) => handleFilter(e.target.value)}
          onFocus={() => dropdown(0, myDropdown)}
          onBlur={() => dropdown(1, myDropdown)}
        />

        <div className={`arrowSelect`}>
          <img src={ArrowDownIcon} alt="" />
        </div>
      </div>
      <div className="dropDown" ref={myDropdown}>
        <div className="listDropDown">
          {query && query.map((value, index) => (
            <div key={index}>
              <div className={`${value == 'No results found' ? 'disabledOption' : 'item'}`} onMouseDown={() => category(value, myInput)}>{value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
