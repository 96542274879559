import React, { useState } from "react";

import Styles from "./ResultsTable.module.scss";
import { useSelector } from "react-redux";
import { orGet } from "../calculation/results-utils";
import Tooltip from "rc-tooltip";

export const Category = {
  CIF: 0,
  Export: 1,
  DeliveredToBlendingFactory: 2,
  DeliveredAtImporterWarehouse: 3,
  ExImporterWarehouse: 4,
  DeliveredAtWholesalerWarehouse: 5,
  FinalPrices: 6,
};

export function ResultsTable({
  data,
  filter,
  headers,
  headersTitle,
  showDetails,
  styles,
  setOnChangeInput,
  checkboxSummary,
}) {
  const [checkedCIF, setCheckedCIF] = useState(true);
  const [checkedPricesExPort, setCheckedPricesExPort] = useState(true);
  const [checkedPriceBlendingFactory, setCheckedPriceBlendingFactory] =
    useState(true);
  const [checkedPriceImporterWarehouse, setCheckedPriceImporterWarehouse] =
    useState(true);
  const [checkedPriceExImporterWarehouse, setCheckedPriceExImporterWarehouse] =
    useState(true);
  const [checkedPriceDeliveredWarehouse, setCheckedPriceDeliveredWarehouse] =
    useState(true);
  const [checkedFinalPrices, setCheckedFinalPrices] = useState(true);
  const [buttonDetails, setButtonDetails] = useState(true);
  const [dataTable, setDataTable] = useState(data);

  React.useEffect(() => {
    setDataTable(data);
  }, [data]);

  const currency = useSelector((state) => state.currency.currency);
  const fcfa = useSelector((state) => state.currency.fcfa);
  const ghs = useSelector((state) => state.currency.ghs);
  const ngn = useSelector((state) => state.currency.ngn);

  function changeValue(value, field, index) {
    if (checkboxSummary) {
      return false;
    }

    if (isNaN(value)) return false;

    const getObject = dataTable[index];

    const changeArray = {
      ...getObject,
      [field]: value === "" ? 0 : parseFloat(value),
    };
    const sort = [
      ...dataTable.map((e, idx) => {
        if (idx !== index) {
          return e;
        } else {
          return changeArray;
        }
      }),
    ];

    setOnChangeInput(sort);
  }

  function extractValue(selector, bold, field, finalResult) {
    function select(it) {
      const selected = selector(it);

      if (
        selected === undefined ||
        (isNaN(selected) && typeof selected !== "string")
      ) {
        return undefined;
      } else {
        return typeof selected === "number" ? selected.toFixed(0) : selected;
      }
    }
    return dataTable.map((it, index) => {
      const selectedValue = select(it);

      if (selectedValue === undefined) {
        return undefined;
      } else {
        return bold ? (
          <th key={index} style={{ minWidth: "8rem" }}>
            {field ? (
              checkboxSummary ? (
                <Tooltip
                  placement="right"
                  trigger="hover"
                  overlay="Close the summary to edit the value"
                >
                  <input
                    style={{ fontWeight: "bold", color: "#444" }}
                    className={Styles.inputCostBreakDown}
                    onChange={(e) => changeValue(e.target.value, field, index)}
                    value={select(it)}
                  />
                </Tooltip>
              ) : (
                <input
                  style={{ fontWeight: "bold", color: "#444" }}
                  className={Styles.inputCostBreakDown}
                  onChange={(e) => changeValue(e.target.value, field, index)}
                  value={select(it)}
                />
              )
            ) : (
              <div
                style={{
                  fontStyle: "normal",
                  cursor: "default",
                  color: finalResult ? "#fff" : "#444",
                }}
              >
                {select(it)}
              </div>
            )}
          </th>
        ) : (
          <td style={{ minWidth: "8rem" }} key={index}>
            {field ? (
              checkboxSummary ? (
                <Tooltip
                  placement="right"
                  trigger="hover"
                  overlay="Close the summary to edit the value"
                >
                  <input
                    style={{ color: "#444" }}
                    className={Styles.inputCostBreakDown}
                    value={select(it)}
                    onChange={(e) => changeValue(e.target.value, field, index)}
                  />
                </Tooltip>
              ) : (
                <input
                  style={{ color: "#444" }}
                  className={Styles.inputCostBreakDown}
                  value={select(it)}
                  onChange={(e) => changeValue(e.target.value, field, index)}
                />
              )
            ) : (
              <div
                style={{
                  fontStyle: "normal",
                  cursor: "default",
                  color: finalResult ? "#fff" : "#444",
                }}
              >
                {select(it)}
              </div>
            )}
          </td>
        );
      }
    });
  }

  function details(label, selectValue, field) {
    return !showDetails ? null : (
      <tr
        className={Styles.Details}
        style={{ backgroundColor: field ? "#f1f3f4" : "none" }}
      >
        <td>{label}</td>
        {extractValue(selectValue, false, field)}
      </tr>
    );
  }
  const longestHeader = headers.reduce(
    (acc, it) => Math.max(acc, it.length),
    0
  );

  return (
    <div style={styles} className={Styles.TableWrapper}>
      <span
        className={`noPrint`}
        style={{ fontSize: "14px", marginBottom: "8px" }}
      >
        * All values in USD/MT otherwise stated
      </span>
      <table className={Styles.Table}>
        <tr
          style={{
            backgroundColor: "#c1dcf8",
            position: "sticky",
            top: "-10px",
          }}
        >
          <th
            style={{
              minWidth: `${longestHeader * 1.25}rem`,
              padding: "5px 0px",
            }}
          >
            {headersTitle}
          </th>
          {headers.map((title, index) => (
            <th key={index}>{title}</th>
          ))}
        </tr>
        <tbody>
          {dataTable.some(
            (it) => it.blending || it.destination || it.portOfEntry
          ) && (
            <>
              <tr>
                <td>Departure City</td>
                {extractValue((it) => it.portOfEntry)}
              </tr>
              {/* <tr>
                <td>Place of bagging or Blending</td>
                {extractValue((it) => it.blending)}
              </tr> */}
              <tr style={{ verticalAlign: "text-top" }}>
                <td style={{ height: "1.75rem" }}>Arrival City</td>
                {extractValue((it) => it.destination)}
              </tr>
            </>
          )}

          {filter.length === 0 ||
            (filter.some((it) => it === Category.CIF) && (
              <>
                <tr>
                  <th>FOB Price at Origin</th>
                  {extractValue((it) => orGet(it.fobPriceAtOrigin), true)}
                </tr>

                <tr>
                  <td>Freight</td>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) + orGet(it.seaFreightInsurrance),
                    false
                  )}
                </tr>

                {details(
                  "Freight Charge",
                  (it) => orGet(it.seaFreight),
                  "seaFreight"
                )}
                {details("Insurrance", (it) => orGet(it.seaFreightInsurrance))}

                <tr>
                  <th>CIF Price at Destination</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some((it) => it === Category.Export) && (
              <>
                <tr>
                  <td>Port Charges</td>
                  {extractValue(
                    (it) =>
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage)
                  )}
                </tr>

                {details("Charges and Fees at Port", (it) =>
                  orGet(it.chargesAndFeesAtPort)
                )}
                {details("Forwarder Fees (port)", (it) =>
                  orGet(it.portForwarderFees)
                )}
                {details("Demurrage", (it) => orGet(it.demurrage))}

                <tr>
                  <td>Custom Charges</td>
                  {extractValue((it) => {
                    return (
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy)
                    );
                  }, false)}
                </tr>

                {details("Custom Levy", (it) => orGet(it.customLevy))}
                {details("ECOWAS + Afrian Union", (it) => orGet(it.ecowasAu))}
                {details("Statistics Levy", (it) => orGet(it.statisticLevy))}
                {details("Solidarity Levy", (it) => orGet(it.solidarityLevy))}
                {details("CCVR Levy", (it) => orGet(it.ccvrLevy))}
                {details("Exim Levy", (it) => orGet(it.eximLevy))}

                <tr>
                  <th>Prices Ex-port</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some(
              (it) => it === Category.DeliveredToBlendingFactory
            ) && (
              <>
                <tr>
                  <td>Direct Transit</td>
                  {extractValue(
                    (it) =>
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees),
                    false
                  )}
                </tr>

                {details("Garanty Found (FGR)", (it) => orGet(it.garantyFound))}
                {details(
                  "Transport to Inland",
                  (it) => orGet(it.transportToInland),
                  "transportToInland"
                )}
                {details(
                  "Other Small Transit Fees",
                  (it) => orGet(it.otherSmallTransitFees),
                  "otherSmallTransitFees"
                )}

                <tr style={{ backgroundColor: "#f1f3f4" }}>
                  <td>Port to Warehouse</td>
                  {extractValue(
                    (it) => orGet(it.portToWarehouse),
                    false,
                    "portToWarehouse"
                  )}
                </tr>
                <tr>
                  <th>Price Delivered to the blending factory</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.portToWarehouse),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some(
              (it) => it === Category.DeliveredAtImporterWarehouse
            ) && (
              <>
                {details("Cost Ingredients", (it) => orGet(it.costIngredients))}
                {details(
                  "Cost Process",
                  (it) => orGet(it.costProccess),
                  "costProccess"
                )}
                {details(
                  "Factory Depreciation",
                  (it) => orGet(it.factoryDepreciation),
                  "factoryDepreciation"
                )}

                <tr>
                  <th>Price Ex Work (FOT)</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.costProccess) +
                      orGet(it.factoryDepreciation) +
                      orGet(it.portToWarehouse),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some((it) => it === Category.ExImporterWarehouse) && (
              <>
                <tr>
                  <td>Overheads Importer</td>
                  {extractValue((it) => {
                    return (
                      orGet(it.importerBankFees) +
                      orGet(it.importerBankInterest) +
                      orGet(it.importerStorageFees) +
                      orGet(it.importerHandlingFees) +
                      orGet(it.importerAdministrativeFees) +
                      orGet(it.importerInsurrance) +
                      orGet(it.importerLossOfWeight) +
                      orGet(it.importerRawMargin) +
                      orGet(it.importerTaxesOnMargin)
                    );
                  }, false)}
                </tr>

                {details(
                  "Bank Fees",
                  (it) => orGet(it.importerBankFees),
                  "importerBankFees"
                )}
                {details(
                  "Bank Interest",
                  (it) => orGet(it.importerBankInterest),
                  "importerBankInterest"
                )}
                {details(
                  "Storage Fees",
                  (it) => orGet(it.importerStorageFees),
                  "importerStorageFees"
                )}
                {details(
                  "Handling Fees",
                  (it) => orGet(it.importerHandlingFees),
                  "importerHandlingFees"
                )}
                {details(
                  "Administrative Fees",
                  (it) => orGet(it.importerAdministrativeFees),
                  "importerAdministrativeFees"
                )}
                {details(
                  "Insurrance",
                  (it) => orGet(it.importerInsurrance),
                  "importerInsurrance"
                )}
                {details(
                  "Loss of Weight",
                  (it) => orGet(it.importerLossOfWeight),
                  "importerLossOfWeight"
                )}
                {details(
                  "Raw Margin",
                  (it) => orGet(it.importerRawMargin),
                  "importerRawMargin"
                )}
                {details(
                  "Taxes on Margin",
                  (it) => orGet(it.importerTaxesOnMargin),
                  "importerTaxesOnMargin"
                )}

                <tr>
                  <th>Prices ex importer warehouse</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.costProccess) +
                      orGet(it.factoryDepreciation) +
                      orGet(it.importerBankFees) +
                      orGet(it.importerBankInterest) +
                      orGet(it.importerStorageFees) +
                      orGet(it.importerHandlingFees) +
                      orGet(it.importerAdministrativeFees) +
                      orGet(it.importerInsurrance) +
                      orGet(it.importerLossOfWeight) +
                      orGet(it.importerRawMargin) +
                      orGet(it.importerTaxesOnMargin) +
                      orGet(it.portToWarehouse),
                    true
                  )}
                </tr>
              </>
            ))}

          {filter.length === 0 ||
            (filter.some(
              (it) => it === Category.DeliveredAtWholesalerWarehouse
            ) && (
              <>
                <tr>
                  <td>Transport to Production Area</td>
                  {extractValue(
                    (it) =>
                      orGet(it.transport) +
                      orGet(it.customLandBorder) +
                      orGet(it.forwardingLandBorder)
                  )}
                </tr>

                {details("Transport", (it) => orGet(it.transport), "transport")}
                {details(
                  "Custom Land Border",
                  (it) => orGet(it.customLandBorder),
                  "customLandBorder"
                )}
                {details(
                  "Forwarding Land Border",
                  (it) => orGet(it.forwardingLandBorder),
                  "forwardingLandBorder"
                )}

                <tr>
                  <th>Price Delivered at the wholesaler warehouse</th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.costProccess) +
                      orGet(it.factoryDepreciation) +
                      orGet(it.importerBankFees) +
                      orGet(it.importerBankInterest) +
                      orGet(it.importerStorageFees) +
                      orGet(it.importerHandlingFees) +
                      orGet(it.importerAdministrativeFees) +
                      orGet(it.importerInsurrance) +
                      orGet(it.importerLossOfWeight) +
                      orGet(it.importerRawMargin) +
                      orGet(it.importerTaxesOnMargin) +
                      orGet(it.transport) +
                      orGet(it.customLandBorder) +
                      orGet(it.forwardingLandBorder) +
                      orGet(it.portToWarehouse),
                    true
                  )}
                </tr>

                <tr>
                  <td>Overheads Wholesaler</td>
                  {extractValue((it) => {
                    return (
                      orGet(it.wholesalerStorageFees) +
                      orGet(it.wholesalerHandlingFees) +
                      orGet(it.wholesalerLossOfWeight) +
                      orGet(it.wholesalerRawMargin) +
                      orGet(it.wholesalerTaxesOnMargin)
                    );
                  }, false)}
                </tr>

                {details(
                  "Storage Fees",
                  (it) => orGet(it.wholesalerStorageFees),
                  "wholesalerStorageFees"
                )}
                {details(
                  "Handling Fees",
                  (it) => orGet(it.wholesalerHandlingFees),
                  "wholesalerHandlingFees"
                )}
                {details(
                  "Loss of Weight",
                  (it) => orGet(it.wholesalerLossOfWeight),
                  "wholesalerLossOfWeight"
                )}
                {details(
                  "Raw Margin",
                  (it) => orGet(it.wholesalerRawMargin),
                  "wholesalerRawMargin"
                )}
                {details(
                  "Taxes on Margin",
                  (it) => orGet(it.wholesalerTaxesOnMargin),
                  "wholesalerTaxesOnMargin"
                )}
              </>
            ))}

          {filter.length === 0 ||
            (filter.some((it) => it === Category.FinalPrices) && (
              <>
                <tr
                  style={{
                    backgroundColor: "#04ba56",
                    color: "#fff  !important",
                    fontStyle: "bold",
                  }}
                >
                  <th
                    style={{
                      padding: "5px 3px",
                      borderRadius: "4px 0px 0px 4px",
                      color: "#fff",
                    }}
                  >
                    Wholesale prices production area
                  </th>
                  {extractValue(
                    (it) =>
                      orGet(it.seaFreight) +
                      orGet(it.seaFreightInsurrance) +
                      orGet(it.fobPriceAtOrigin) +
                      orGet(it.customLevy) +
                      orGet(it.ecowasAu) +
                      orGet(it.statisticLevy) +
                      orGet(it.solidarityLevy) +
                      orGet(it.ccvrLevy) +
                      orGet(it.eximLevy) +
                      orGet(it.chargesAndFeesAtPort) +
                      orGet(it.portForwarderFees) +
                      orGet(it.demurrage) +
                      orGet(it.garantyFound) +
                      orGet(it.transportToInland) +
                      orGet(it.otherSmallTransitFees) +
                      orGet(it.costProccess) +
                      orGet(it.factoryDepreciation) +
                      orGet(it.importerBankFees) +
                      orGet(it.importerBankInterest) +
                      orGet(it.importerStorageFees) +
                      orGet(it.importerHandlingFees) +
                      orGet(it.importerAdministrativeFees) +
                      orGet(it.importerInsurrance) +
                      orGet(it.importerLossOfWeight) +
                      orGet(it.importerRawMargin) +
                      orGet(it.importerTaxesOnMargin) +
                      orGet(it.transport) +
                      orGet(it.customLandBorder) +
                      orGet(it.forwardingLandBorder) +
                      orGet(it.portToWarehouse) +
                      orGet(it.wholesalerStorageFees) +
                      orGet(it.wholesalerHandlingFees) +
                      orGet(it.wholesalerLossOfWeight) +
                      orGet(it.wholesalerRawMargin) +
                      orGet(it.wholesalerTaxesOnMargin),
                    true,
                    false,
                    true
                  )}
                </tr>

                <tr style={{ height: "0.25rem" }} />

                {currency === "USD" && (
                  <tr
                    style={{
                      backgroundColor: "#04ba56",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px 3px",
                        borderRadius: "4px 0px 0px 4px",
                      }}
                    >
                      Wholesale prices production area (USD/50kg bag)
                    </td>
                    {extractValue(
                      (it) =>
                        (orGet(it.seaFreight) +
                          orGet(it.seaFreightInsurrance) +
                          orGet(it.fobPriceAtOrigin) +
                          orGet(it.customLevy) +
                          orGet(it.ecowasAu) +
                          orGet(it.statisticLevy) +
                          orGet(it.solidarityLevy) +
                          orGet(it.ccvrLevy) +
                          orGet(it.eximLevy) +
                          orGet(it.chargesAndFeesAtPort) +
                          orGet(it.portForwarderFees) +
                          orGet(it.demurrage) +
                          orGet(it.garantyFound) +
                          orGet(it.transportToInland) +
                          orGet(it.otherSmallTransitFees) +
                          orGet(it.costProccess) +
                          orGet(it.factoryDepreciation) +
                          orGet(it.importerBankFees) +
                          orGet(it.importerBankInterest) +
                          orGet(it.importerStorageFees) +
                          orGet(it.importerHandlingFees) +
                          orGet(it.importerAdministrativeFees) +
                          orGet(it.importerInsurrance) +
                          orGet(it.importerLossOfWeight) +
                          orGet(it.importerRawMargin) +
                          orGet(it.importerTaxesOnMargin) +
                          orGet(it.transport) +
                          orGet(it.customLandBorder) +
                          orGet(it.forwardingLandBorder) +
                          orGet(it.portToWarehouse) +
                          orGet(it.wholesalerStorageFees) +
                          orGet(it.wholesalerHandlingFees) +
                          orGet(it.wholesalerLossOfWeight) +
                          orGet(it.wholesalerRawMargin) +
                          orGet(it.wholesalerTaxesOnMargin)) /
                        20,
                      false,
                      false,
                      true
                    )}
                  </tr>
                )}

                {currency === "FCFA" && (
                  <tr
                    style={{
                      backgroundColor: "#04ba56",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px 3px",
                        borderRadius: "4px 0px 0px 4px",
                      }}
                    >
                      Wholesale prices production area (FCFA/50kg bag)
                    </td>
                    {extractValue(
                      (it) =>
                        (orGet(it.seaFreight) +
                          orGet(it.seaFreightInsurrance) +
                          orGet(it.fobPriceAtOrigin) +
                          orGet(it.customLevy) +
                          orGet(it.ecowasAu) +
                          orGet(it.statisticLevy) +
                          orGet(it.solidarityLevy) +
                          orGet(it.ccvrLevy) +
                          orGet(it.eximLevy) +
                          orGet(it.chargesAndFeesAtPort) +
                          orGet(it.portForwarderFees) +
                          orGet(it.demurrage) +
                          orGet(it.garantyFound) +
                          orGet(it.transportToInland) +
                          orGet(it.otherSmallTransitFees) +
                          orGet(it.costProccess) +
                          orGet(it.factoryDepreciation) +
                          orGet(it.importerBankFees) +
                          orGet(it.importerBankInterest) +
                          orGet(it.importerStorageFees) +
                          orGet(it.importerHandlingFees) +
                          orGet(it.importerAdministrativeFees) +
                          orGet(it.importerInsurrance) +
                          orGet(it.importerLossOfWeight) +
                          orGet(it.importerRawMargin) +
                          orGet(it.importerTaxesOnMargin) +
                          orGet(it.transport) +
                          orGet(it.customLandBorder) +
                          orGet(it.forwardingLandBorder) +
                          orGet(it.portToWarehouse) +
                          orGet(it.wholesalerStorageFees) +
                          orGet(it.wholesalerHandlingFees) +
                          orGet(it.wholesalerLossOfWeight) +
                          orGet(it.wholesalerRawMargin) +
                          orGet(it.wholesalerTaxesOnMargin)) *
                        (fcfa / 20),
                      false,
                      false,
                      true
                    )}
                  </tr>
                )}

                {currency === "GHS" && (
                  <tr
                    style={{
                      backgroundColor: "#04ba56",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px 3px",
                        borderRadius: "4px 0px 0px 4px",
                      }}
                    >
                      Wholesale prices production area (GH₵/50kg bag)
                    </td>
                    {extractValue(
                      (it) =>
                        (orGet(it.seaFreight) +
                          orGet(it.seaFreightInsurrance) +
                          orGet(it.fobPriceAtOrigin) +
                          orGet(it.customLevy) +
                          orGet(it.ecowasAu) +
                          orGet(it.statisticLevy) +
                          orGet(it.solidarityLevy) +
                          orGet(it.ccvrLevy) +
                          orGet(it.eximLevy) +
                          orGet(it.chargesAndFeesAtPort) +
                          orGet(it.portForwarderFees) +
                          orGet(it.demurrage) +
                          orGet(it.garantyFound) +
                          orGet(it.transportToInland) +
                          orGet(it.otherSmallTransitFees) +
                          orGet(it.costProccess) +
                          orGet(it.factoryDepreciation) +
                          orGet(it.importerBankFees) +
                          orGet(it.importerBankInterest) +
                          orGet(it.importerStorageFees) +
                          orGet(it.importerHandlingFees) +
                          orGet(it.importerAdministrativeFees) +
                          orGet(it.importerInsurrance) +
                          orGet(it.importerLossOfWeight) +
                          orGet(it.importerRawMargin) +
                          orGet(it.importerTaxesOnMargin) +
                          orGet(it.transport) +
                          orGet(it.customLandBorder) +
                          orGet(it.forwardingLandBorder) +
                          orGet(it.portToWarehouse) +
                          orGet(it.wholesalerStorageFees) +
                          orGet(it.wholesalerHandlingFees) +
                          orGet(it.wholesalerLossOfWeight) +
                          orGet(it.wholesalerRawMargin) +
                          orGet(it.wholesalerTaxesOnMargin)) *
                        (ghs / 20),
                      false,
                      false,
                      true
                    )}
                  </tr>
                )}

                {currency === "NGN" && (
                  <tr
                    style={{
                      backgroundColor: "#04ba56",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <td
                      style={{
                        padding: "5px 3px",
                        borderRadius: "4px 0px 0px 4px",
                      }}
                    >
                      Wholesale prices production area (NGN/50kg bag)
                    </td>
                    {extractValue(
                      (it) =>
                        (orGet(it.seaFreight) +
                          orGet(it.seaFreightInsurrance) +
                          orGet(it.fobPriceAtOrigin) +
                          orGet(it.customLevy) +
                          orGet(it.ecowasAu) +
                          orGet(it.statisticLevy) +
                          orGet(it.solidarityLevy) +
                          orGet(it.ccvrLevy) +
                          orGet(it.eximLevy) +
                          orGet(it.chargesAndFeesAtPort) +
                          orGet(it.portForwarderFees) +
                          orGet(it.demurrage) +
                          orGet(it.garantyFound) +
                          orGet(it.transportToInland) +
                          orGet(it.otherSmallTransitFees) +
                          orGet(it.costProccess) +
                          orGet(it.factoryDepreciation) +
                          orGet(it.importerBankFees) +
                          orGet(it.importerBankInterest) +
                          orGet(it.importerStorageFees) +
                          orGet(it.importerHandlingFees) +
                          orGet(it.importerAdministrativeFees) +
                          orGet(it.importerInsurrance) +
                          orGet(it.importerLossOfWeight) +
                          orGet(it.importerRawMargin) +
                          orGet(it.importerTaxesOnMargin) +
                          orGet(it.transport) +
                          orGet(it.customLandBorder) +
                          orGet(it.forwardingLandBorder) +
                          orGet(it.portToWarehouse) +
                          orGet(it.wholesalerStorageFees) +
                          orGet(it.wholesalerHandlingFees) +
                          orGet(it.wholesalerLossOfWeight) +
                          orGet(it.wholesalerRawMargin) +
                          orGet(it.wholesalerTaxesOnMargin)) *
                        (ngn / 20),
                      false,
                      false,
                      true
                    )}
                  </tr>
                )}
              </>
            ))}
        </tbody>
      </table>
    </div>
  );
}
