import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import firebase from 'firebase/app';
import {
  Button as FloatingButton,
  darkColors,
  lightColors,
} from 'react-floating-action-button';

import useErrorHandler from '../../../../hooks/useErrorHandler';
import Results from './Results';

import * as Actions from '../../../../redux/actions';

import Styles from './index.module.scss';
import { FloatActionButtons } from '../../../CommonStyles.module.scss';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { OWN_FORMULAS } from '../../../../data';
import EditProductDialog from '../EditProductDialog';
import PreparationContents from '../PreparationContents';
import { useIsAdmin } from '../../../../hooks';
import ShareFormulaDialog from '../ShareFormulaDialog';
import useOwnFormulas from '../../../../hooks/useOwnFormulas';
import toast, { Toaster } from 'react-hot-toast';

export function FormulaOverview() {
  const isAdmin = useIsAdmin()
  const dispatch = useDispatch();

  const firestore = firebase.firestore()
  const collection = firestore.collection("formulas")

  const saveErrorHandler = useErrorHandler('Could not save your formula')
  const notifications = React.useContext(NotificationContext)

  const id = useSelector(state => state.formula.id)
  const name = useSelector(state => state.formula.name)
  const products = useSelector(state => state.formula.products)
  const isPublic = useSelector(state => state.formula.public)

  const ownFormulas = useOwnFormulas(true)
  const isOwnFormula = !id || ownFormulas.some(it => it.id === id)

  const [showResults, setShowResults] = React.useState(false)
  const [editingName, setEditingName] = React.useState(false)
  const [sharing, setSharing] = React.useState(false)

  const percentageSum = parseFloat(
    products.reduce((a, b) => a + b.percentage, 0).toFixed(1)
  )

  function toggleNameEditor() {
    setEditingName(!editingName)
  }

  function create(payload) {
    collection.add(payload)
      .then(({ id }) => {
        OWN_FORMULAS.forget()
        localStorage.removeItem('@fcc/ownFormulas')
        toast.success("Your formula was saved successfully!!")
        window.location.href = `/my/formula/${id}`
      }).catch(saveErrorHandler)
  }

  function handleAskSaveFirestore() {
    const data = { name, public: isPublic, products, email: firebase.auth().currentUser.email }

    if (id) {
      const docRef = collection.doc(id)
      docRef.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          docRef.set(data)
            .then(() => {
              OWN_FORMULAS.forget()
              localStorage.removeItem('@fcc/ownFormulas')
              toast.success("Formula saved!")
            })
            .catch(saveErrorHandler)
        } else {
          create(data)
        }
      }).catch(saveErrorHandler)
    } else {
      create(data)
    }
  }

  function handleAskDeleteFirestore() {
    if (window.confirm(`Are you sure you want to delete ${name}? This is a one way action! `)) {
      collection.doc(id).delete()
        .then(() => {
          OWN_FORMULAS.forget()
          localStorage.removeItem('@fcc/ownFormulas')
          notifications.sendWarning(`The formula ${name} was successfully deleted`)
          dispatch(Actions.clearFormula())
          window.location.href = '/my/formula'
        }).catch(saveErrorHandler)
    }
  }

  function handleShare() {
    if (id) {
      setSharing(true)
    }
  }

  function fork() {
    create({
      name: `${name} - Copy`,
      public: false,
      products,
      email: firebase.auth().currentUser.email,
      fork: id
    })
  }

  const nameEditor = <input
    className={Styles.EditNameField}
    max={24}
    value={name}
    onChange={e => dispatch(Actions.updateName(e.target.value))}
  />

  return (
    <div className={Styles.Grid}>
      <Toaster />
      <EditProductDialog />
      {id && <ShareFormulaDialog id={id} visible={sharing} setVisible={setSharing} />}
      <div className={Styles.Header}>
        <div>
          <div>
            {editingName ? nameEditor : <h1 className={Styles.NameTitle}><span>{name}</span></h1>}
            {isOwnFormula && (
              <i
                className={`pi pi-${editingName ? `check ${Styles.ConfirmState}` : 'pencil'} ${Styles.EditNameButton}`}
                title={editingName ? "Save" : "Edit Name"}
                onClick={toggleNameEditor}
              />
            )}
          </div>
          <PreparationContents />
        </div>
        
          {percentageSum > 100 && toast.success("The sum of the percentages has to be 100!")}
        
      </div>
      {showResults && <Results />}
      <div className={FloatActionButtons}>
        {isOwnFormula && (
          <div>
            <FloatingButton
              tooltip="Save"
              icon="fa fa-save"
              onClick={handleAskSaveFirestore}
              styles={{ backgroundColor: darkColors.blue, color: lightColors.white }}
            />
          </div>
        )}
        {
          !isOwnFormula && (
            <div>
              <FloatingButton
                tooltip="Fork"
                icon="fa fa-code-branch"
                onClick={fork}
                styles={{ backgroundColor: darkColors.blue, color: lightColors.white }}
              />
            </div>
          )
        }
        <div>
          <FloatingButton
            tooltip={showResults ? 'Show Input' : 'Show Results'}
            icon={`fa fa-${showResults ? 'list' : 'dollar-sign'}`}
            onClick={() => setShowResults(!showResults)}
            styles={{ backgroundColor: '#2196F3', color: 'white' }}
          />
        </div>
        {
          id && isOwnFormula && (
            <div>
              <FloatingButton
                tooltip="Delete"
                icon="fa fa-trash-alt"
                onClick={handleAskDeleteFirestore}
                styles={{ backgroundColor: darkColors.red, color: lightColors.white }}
              />
            </div>
          )
        }
        <div>
          <FloatingButton
            tooltip="Print"
            icon="fa fa-print"
            onClick={() => window.print()}
          />
        </div>
        {(isOwnFormula || isPublic) && (
          <div>
            <FloatingButton
              tooltip={id ? "Share" : "Please save your formula before sharing"}
              icon="fa fa-share-alt"
              styles={{
                backgroundColor: '#F44336',
                color: 'white',
                ...(id ? {} : { cursor: 'not-allowed' })
              }}
              onClick={handleShare}
            />
          </div>
        )}
        {
          isAdmin && (
            <div>
              <FloatingButton
                tooltip={`${isPublic ? 'Public' : 'Private'} Formula. Click to make ${isPublic ? 'Private' : 'Public'}`}
                styles={{ cursor: 'pointer', color: 'white', backgroundColor: isPublic ? '#00db00' : '#f20202' }}
                onClick={() => dispatch(Actions.updateVisibility(!isPublic))}
                icon={isPublic ? 'fa fa-lock-open' : 'fa fa-lock'}
              />
            </div>
          )
        }
      </div>
    </div>
  )
}
