import React from 'react';
import { Dropdown } from 'primereact/dropdown';

import { TransitMode, TransitModeDisplay } from '../../../const';

export default function TransitModeSelector({ id, value, onChange }) {

  const entries = Object.values(TransitMode).map(value => ({ value, label: TransitModeDisplay[value] }))
  console.log('value', value)
  return (
    <Dropdown
      inputId={id}
      options={entries}
      value={value || TransitMode.NONE}
      onChange={e => onChange(e.value)}
    />
  )
}
