import React from 'react';
import deepEqual from 'fast-deep-equal';

export function useDeepCompareMemoized(value) {
  const ref = React.useRef()

  if (!deepEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function setQueryParameter(history, key, value) {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, value);

  history.push({
    search: queryParams.toString()
  })
}

// https://stackoverflow.com/a/63274702/9893963
export function distinctBy(array, selector) {
  let data = new Map()
  for (let obj of array) {
    data.set(selector(obj), obj);
  }
  return [...data.values()]
}

export function addTypeDataToProduct(product, types) {
  const type = types.find(it => it.id === product.type)
  return {
    ...product, type
  }
}

export function areProductsEquals(a, b, ignoredKeys = []) {
  const keys = ['type', 'transit', 'minFob', 'maxFob', 'percentage', 'seaFreight', 'storageDuration', 'storageCost', 'loanCashoutDuration', 'bankInterestRate', 'demurrageDuration', 'demurragePrice', 'demurrage', 'placeOfBlending', 'passageMode', 'port']

  if (!a || !b) {
    return false
  }

  if (a === b) {
    return true
  }

  return keys.filter(it => !ignoredKeys.includes(it)).every(key => a[key] === b[key])
}

export function areProductArrayEquals(a, b, ignoredKeys = []) {
  if (!a || !b) {
    return false
  }

  if (a === b) {
    return true
  }

  if (a.length !== b.length) {
    return false
  }

  return a.every((it, index) => areProductsEquals(it, b[index], ignoredKeys))
}

export function toUTC(date) {
  return new Date(date.toUTCString().slice(0, -4));
}

export function nowInUtc() {
  return toUTC(new Date())
}

export function utcToLocal(date) {
  return new Date(date.toString() + ' UTC')
}
