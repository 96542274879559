import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useProductTypes from "../../../../hooks/useProductTypes";
import Styles from "./index.module.scss";
import { EditProductForm } from "./EditProductForm";
import * as Actions from "../../../../redux/actions";
import { Carousel } from "primereact/carousel";
import { areProductsEquals } from "../../../../utils";
import { Button } from "primereact/button";
import toast, { Toaster } from "react-hot-toast";

export default function EditProductDialog({
  onSave,
  customFooter,
  port,
  dropdowns,
}) {
  const index = useSelector((state) => state.ui.editingProduct);
  const products = useSelector((state) => state.formula.products);
  const product = useSelector((state) => state.formula.products[index]);
  const formulaName = useSelector((state) => state.formula.name);
  const productType = useProductTypes(product?.type);
  const dispatch = useDispatch();
  const [bufferedData, setBufferedData] = React.useState(products);

  const visible = index !== null;

  React.useEffect(() => {
    if (visible) {
      setBufferedData(products);
    }
  }, [visible]);

  function handleSave() {
    bufferedData.forEach((data, index) => {
      return dispatch(Actions.updateProduct(index, data));
    });
    dispatch(Actions.uiStopEditProduct());
    onSave && onSave(bufferedData);
  }

  function handleHide() {
    if (visible) {
      const notChanged = products.every((data, index) =>
        areProductsEquals(data, bufferedData[index], ["type", "percentage"])
      );
      if (notChanged) {
        dispatch(Actions.uiStopEditProduct());
      } else {
        toast((t) => (
          <div className="deleteToastAdm">
            <h1 style={{ fontSize: 18 }}>
              You will lose any unsaved changes you have made, do you want to
              proceed?
            </h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <button
                className="btn btn--secondary"
                onClick={() => toast.dismiss(t.id)}
              >
                Cancel
              </button>
              <button
                style={{ marginLeft: "5px" }}
                className="btn btn--primary center"
                onClick={() => {
                  toast.dismiss(t.id);
                  dispatch(Actions.uiStopEditProduct());
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        ));
      }
    }
  }

  function footer() {
    function buttons() {
      return (
        <div className={Styles.Buttons}>
          <Button label="Keep" icon="fa fa-save" onClick={handleSave} />
        </div>
      );
    }

    return customFooter ? customFooter(/*buttons()*/) : ""; /*buttons()*/
  }

  function itemTemplate(data) {
    function mutateBufferedData(newData) {
      const newArray = [...bufferedData];
      newArray[data.index] = { ...bufferedData[index], ...newData };
      setBufferedData(newArray);
    }

    return dropdowns ? (
      <EditProductForm
        data={bufferedData[data.index]}
        onChange={mutateBufferedData}
        portPreferences={port}
        dropdowns={dropdowns}
      />
    ) : (
      <EditProductForm
        data={bufferedData[data.index]}
        onChange={mutateBufferedData}
        portPreferences={port}
      />
    );
  }

  return (
    visible && (
      <div className="modalFormulas">
        <div className="modalFormulasWrapper">
          <div style={{ width: "100%" }}>
            <div className="EditModalFormulas">
              <div className="EditModalFormulasInside">
                <span className={Styles.Title}>
                  Editing{" "}
                  <span
                    style={{
                      color: productType?.isFiller ? "#9146fa" : "#49b2f5",
                    }}
                  >
                    {productType?.name}
                  </span>{" "}
                  on <span style={{ color: "#04ba56" }}>{formulaName}</span> at
                  position {index + 1}
                </span>
                <br />
                {footer()}
                <br />
                <Carousel
                  onPageChange={(e) =>
                    visible && dispatch(Actions.uiEditProduct(e.page))
                  }
                  value={bufferedData.map((data, index) => ({
                    ...data,
                    index,
                  }))}
                  numVisible={1}
                  numScroll={1}
                  page={index}
                  itemTemplate={itemTemplate}
                />
                <br />
                <div className="div">
                  <button
                    className="btn btn--secondary"
                    onClick={() => handleHide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn--primary"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
