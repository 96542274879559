import React from 'react'
import Tooltip from 'rc-tooltip'
import PropTypes from 'prop-types'
import 'rc-tooltip/assets/bootstrap.css'

const propTypes = {
  /** A tooltip can be placed in one of these places 'left','right','top','bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight' */
  placement: PropTypes.oneOf([
    'left',
    'right',
    'top',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
  ]),

  /** A tooltip can have one or more of these triggers 'hover','click','focus' */
  trigger: PropTypes.oneOf(['hover', 'click', 'focus']),

  /** A tooltip should have content with the type of a String or a React Element  */
  overlay: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

const defaultProps = {
  placement: 'top',
  trigger: 'hover',
}

const TooltipWrapper = ({ children, ...props }) =>
  props.overlay ? <Tooltip {...props}>{children}</Tooltip> : children

TooltipWrapper.propTypes = propTypes
TooltipWrapper.defaultProps = defaultProps

export default TooltipWrapper
