import React from 'react';

import Styles from './index.module.scss';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useProductTypes } from '../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';

import * as Actions from '../../../../redux/actions';
import {OverlayPanel} from "primereact/overlaypanel";

import { optimizePercentages } from 'nutrient-optimization';

export default function PreparationContents() {

  const dispatch = useDispatch()
  const panel = React.useRef()

  const productTypes = useProductTypes()
  const productsSelected = useSelector(state => state.formula.products.map(it => it.type))

  const [N, setN] = React.useState(0)
  const [P, setP] = React.useState(0)
  const [K, setK] = React.useState(0)
  const [Ca, setCa] = React.useState(0)
  const [Mg, setMg] = React.useState(0)
  const [S, setS] = React.useState(0)
  const [Zn, setZn] = React.useState(0)
  const [B, setB] = React.useState(0)
  const [Cu, setCu] = React.useState(0)
  const [Cl, setCl] = React.useState(0)

  function optimize() {
    const products = productsSelected.map(id => productTypes.find(it => id === it.id))
    const input = { N, P2O5: P, K2O: K, Ca, Mg, S, Zn, B, Cu, Cl }
    const result = optimizePercentages(input, products)

    result.forEach(it => {
      const index = products.findIndex(p => p.id === it.typeId)
      const percentage = parseFloat(it.percentage.toFixed(1))
      dispatch(Actions.updateProduct(index, { percentage }))
    })
  }

  function openPanel(e) {
    if (panel.current) {
      panel.current.toggle(e)
    }
  }

  return (
    <>
      Preparation:
      <div className={Styles.PreparationContents}>
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon" title="Nitrogen">N</span>
          <InputNumber min={0} value={N} onValueChange={e => setN(e.value) }/>
        </div>
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon" title="Phosphorus">P</span>
          <InputNumber min={0} value={P} onValueChange={e => setP(e.value)} />
        </div>
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon" title="Potassium">K</span>
          <InputNumber min={0} value={K} onValueChange={e => setK(e.value)}/>
        </div>
        <Button label="Other Nutrients" className="p-button-secondary" onClick={openPanel}/>
        <OverlayPanel ref={panel}>
          <div style={{ width: 256, margin: 4 }}>
            {
              [
                ["Ca", Ca, setCa],
                ["Mg", Mg, setMg],
                ["S", S, setS],
                ["Zn", Zn, setZn],
                ["B", B, setB],
                ["Cu", Cu, setCu],
                ["Cl", Cl, setCl]
              ].map(([label, value, setValue]) => (
                <div className="p-inputgroup" style={{ height: 16, marginBottom: 2, width: '100%' }}>
                  <label htmlFor={label} className="p-inputgroup-addon" style={{ width: '3rem', borderRadius: 0 }}>{label}</label>
                  <InputNumber
                    inputId={label}
                    value={value}
                    mode="decimal"
                    maxFractionDigits={2}
                    minFractionDigits={2}
                    min={0}
                    onValueChange={e => setValue(e.value)}
                    style={{ width: '100%', height: 16 }}
                    inputStyle={{ borderRadius: 0, width: '100%' }}
                  />
                </div>
              ))
            }
          </div>
        </OverlayPanel>
        <Button label="Go" onClick={optimize}/>
      </div>
    </>
  )
}

