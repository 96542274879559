import { Types } from '../actions/';

const defaultState = Object.freeze({
  editingProduct: null
})

export default (state = defaultState, action) => {
  switch (action.type) {

    case Types.UI_EDITING_PRODUCT: return {
      ...state,
      editingProduct: action.payload
    }

    default: return state
  }
}
