"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fillMissingWith = exports.sortCompositionElementsOfProducts = exports.sortProductsPerAmountOfDifferentCompositionElements = exports.filterNonEmptyExpectedComposition = exports.optimizePercentages = void 0;
function optimizePercentages(expectedResult, availableProducts) {
    expectedResult = (0, exports.filterNonEmptyExpectedComposition)(expectedResult);
    availableProducts = (0, exports.sortProductsPerAmountOfDifferentCompositionElements)(availableProducts);
    availableProducts = (0, exports.sortCompositionElementsOfProducts)(availableProducts);
    if (Object.keys(expectedResult).length === 0) {
        return availableProducts.map((product) => ({ typeId: product.id, percentage: 0 }));
    }
    if (availableProducts.length === 0) {
        return [];
    }
    const resultOfProcessing = availableProducts.reduce((acc, product) => {
        var _a;
        if (Object.keys(product.composition).length === 0)
            return acc;
        const element = Object.keys(product.composition)[0];
        const calculatedWeight = getWeightUntil(expectedResult[element], element, product);
        const elementsInWeight = getElementsInWeightOf(calculatedWeight, product);
        const allNewAndOldElements = Array.from(new Set([...Object.keys(acc.elements), ...Object.keys(elementsInWeight)]));
        const newWeight = Object.fromEntries(allNewAndOldElements
            .map((it) => { var _a, _b; return [it, ((_a = elementsInWeight[it]) !== null && _a !== void 0 ? _a : 0) + ((_b = acc.elements[it]) !== null && _b !== void 0 ? _b : 0)]; }));
        const mostPresentElementInCommon = Object.entries(product.composition)
            .filter(([k]) => Object.keys(acc.elements).includes(k))
            .sort((a, b) => b[1] - a[1]);
        const mostPresentElementInCommonValue = !mostPresentElementInCommon.length
            ? 0
            : (_a = acc.elements[mostPresentElementInCommon[0][0]]) !== null && _a !== void 0 ? _a : 0;
        const percentage = calculatedWeight - mostPresentElementInCommonValue;
        const newMap = new Map(acc.percentage);
        newMap.set(product, percentage);
        return {
            percentage: newMap,
            elements: newWeight
        };
    }, { percentage: new Map(), elements: {} });
    const result = Array.from(resultOfProcessing.percentage.entries());
    // fill missing with filler
    const filler = availableProducts.find((it) => it.isFiller);
    const filledResult = filler
        ? (0, exports.fillMissingWith)(result, filler)
        : result;
    return filledResult
        .map(([productType, percentage]) => ({
        typeId: productType.id,
        percentage
    }));
}
exports.optimizePercentages = optimizePercentages;
const filterNonEmptyExpectedComposition = (expectedResult) => Object.fromEntries(Object
    .entries(expectedResult)
    .filter(([_, value]) => value > 0));
exports.filterNonEmptyExpectedComposition = filterNonEmptyExpectedComposition;
const sortProductsPerAmountOfDifferentCompositionElements = (products) => products
    .sort((z, a) => {
    const sizeA = Object.keys(a.composition).length;
    const sizeZ = Object.keys(z.composition).length;
    return sizeA - sizeZ;
});
exports.sortProductsPerAmountOfDifferentCompositionElements = sortProductsPerAmountOfDifferentCompositionElements;
const sortCompositionElementsOfProducts = (products) => products.map((product) => {
    return Object.assign(Object.assign({}, product), { composition: Object.fromEntries(Object.entries(product.composition).sort((z, a) => (a[1] - z[1]))) });
});
exports.sortCompositionElementsOfProducts = sortCompositionElementsOfProducts;
const getWeightUntil = (percentage, element, product) => {
    var _a;
    const percentageOfElement = (_a = product.composition[element]) !== null && _a !== void 0 ? _a : 0;
    return percentageOfElement !== 0
        ? (100 * percentage) / percentageOfElement
        : 0;
};
const getElementsInWeightOf = (weight, product) => {
    return Object.fromEntries(Object.entries(product.composition).map(([k, v]) => {
        return [k, (v / 100) * weight];
    }));
};
const fillMissingWith = (result, filler) => {
    const percentageSum = Math.min(100, result.reduce((acc, [_, percentage]) => acc + percentage, 0));
    return [...result, [filler, 100 - percentageSum]];
};
exports.fillMissingWith = fillMissingWith;
