import React from 'react';
import { Card } from 'primereact/card';

export default function AboutUs() {
  return (
    <section style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: '2rem', marginBottom: 8 }}>Contact Us</h1>
      <Card>
        <section>
          <img src="/logo.png" alt="IFDC" style={{ width: 'auto', height: 32 }}/>
          <p>North & West Africa No. 113A Mbabane Avenue,</p>
          <p>East Legon Residential Area – PMB CT 284, Cantonments,</p>
          <p>Accra Ghana</p>
        </section>
        <section>
          <i className="fa fa-phone"/> Tel: <a href="tel:+233-(0)-560-027-917/8">+233 (0) 560 027 917/8</a>
          <br/>
          <i className="fa fa-globe"/> Website: <a href="http://www.ifdc.org" target="_blank" rel="noopener noreferrer">http://www.ifdc.org</a>
          <br/>
          <i className="fa fa-envelope"/> Email: <a href="mailto:fertilizercostsimulator@ifdc.org">fertilizercostsimulator@ifdc.org</a>
        </section>
      </Card>
    </section>
  )
}
