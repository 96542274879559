import React from 'react';

export default function ItemWithCountryFlag({ display, alphaCode, countryDisplayName }) {
  return (
    <>
      <img
        style={{ height: 16, marginRight: 2 }}
        src={`https://restcountries.eu/data/${alphaCode?.toLowerCase() || ''}.svg`}
        alt={`${countryDisplayName || display} Flag`}
      />
      <span style={{ fontSize: 16, verticalAlign: "middle" }}>{display}</span>
    </>
  )
}
