import React from 'react';
import { OWN_FORMULAS } from '../data';
import { auth } from '../services/firebase';

export default (load) => {
  const [data, setData] = React.useState([])
  const [isFetching, setFetching] = React.useState(false)

  const currentEmail = auth.currentUser?.email
  React.useEffect(() => {
    if (!isFetching && load) {
      setFetching(true)
      OWN_FORMULAS.get()
        .then((formulas) => {
          setData(formulas)
          setFetching(false)
        }).catch(console.error)
   }
  }, [load, currentEmail]);

  return data;
}
