import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import useTowns from '../../../hooks/useTowns';
import ItemWithCountryFlag from '../ItemWithCountryFlag';
import {FormatOptions, formatTown} from "../../../formatter";

export default ({ id, value, onChange, disableNull }) => {
  const [towns] = useTowns()

  const items = [
    disableNull ? undefined : { value: null, label: 'At Origin or None' },
    ...towns.map(it => ({
      value: it.id,
      label: formatTown(it, [FormatOptions.COUNTRY_ALPHA]),
      ...it
    })).sort((a, b) => a.label.localeCompare(b.label))
  ].filter(Boolean)

  function createItemTemplate(town) {
    const { name, country, label } = town
    return name && country ? (
      <ItemWithCountryFlag
        display={formatTown(town, [FormatOptions.COUNTRY_ALPHA])}
        alphaCode={country?.alphaCode}
        countryDisplayName={country?.displayName}
      />
    ) : label
  }

  return (
    <Dropdown
      id={id}
      value={value}
      options={items}
      appendTo={document.body}
      placeholder="Select a Town"
      onChange={({ value }) => onChange(value)}
      itemTemplate={createItemTemplate}
      filterBy="label"
      filter
    />
  )
}
