import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../redux/actions";
import SelectDropdown from "../../utils/SelectDropdown";

import { store } from "../../../";
import EditProductDialog from "../FormulasPage/EditProductDialog";

import {
  usePorts,
  usePlacesOfBlending,
  useFinalDestinations,
  useProductTypes,
} from "../../../hooks";

export function startEdit(index, formula) {
  const dispatch = store.dispatch;
  const { id, name, products } = formula.formula;

  dispatch(Actions.loadFormula(id, name, products, false));
  dispatch(Actions.compEditFormula(index));
  if (products.length > 0) {
    dispatch(Actions.uiEditProduct(0));
  }
}

export function TemporaryEditor(props) {
  const dispatch = useDispatch();
  const index = useSelector((state) => state.compare.editingIndex);
  const formula = useSelector((state) => state.compare.formulas[index]);

  const [portPreferences, setPortPreferences] = React.useState("");

  const placesOfProcessing = usePlacesOfBlending();

  const selectListPOP = placesOfProcessing[0].map((place) => {
    return place.name + ` (${place.country.alphaCode})`;
  });
  selectListPOP.sort();

  const finalDestinations = useFinalDestinations();
  const selectListFD = finalDestinations[(0, 0)].map((destination) => {
    return destination.town.name + ` (${destination.town.country.alphaCode})`;
  });
  selectListFD.sort();

  const ports = usePorts();
  const selectListPOE = ports[0].map((port) => {
    return port.name + ` (${port.country.alphaCode})`;
  });
  selectListPOE.sort();

  const handleChangeDropdownPlaceProcessing = (value) => {
    props.setFlagPOPtable(0);
    const placeOfProcessingAux = placesOfProcessing[0].find(
      (placeProcessing) => placeProcessing.id === value.id
    );
    dispatch(Actions.compUpdateBlending(index, placeOfProcessingAux.id));
  };

  const handleChangeDropdownDestination = (value) => {
    props.setFlagDEStable(0);
    const destinationAux = finalDestinations[0].find(
      (destination) => destination.id === value.id
    );
    dispatch(Actions.compUpdateDestination(index, destinationAux.id));
  };

  const handleChangeDropdownPorts = (value) => {
    props.setFlagPOEtable(0);
    const portsAux = ports[0].find((port) => port.id === value.id);
    setPortPreferences(portsAux.id);
    dispatch(Actions.compUpdatePorts(index, portsAux.id));
  };

  function handleEditSave(products) {
    let addPOE = formula.portOfEntry
      ? products.map((e) => {
          return {
            ...e,
            port: formula.portOfEntry,
          };
        })
      : products;

    dispatch(Actions.compUpdateProducts(index, addPOE));
  }

  function footer(buttons) {
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            flexGrow: 100,
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <SelectDropdown
              myLabel={"Departure City"}
              inputName={"portOfEntry"}
              myId={"searchbarPOE"}
              myPlaceholder={"Select the departure city"}
              selectOptions={selectListPOE}
              completeOptions={ports[0]}
              value={
                formula?.portOfEntry
                  ? props.flagPOEtable === 1
                    ? props.dropdownSelectedPOE
                    : ports[0].find((e) => e.id === formula.portOfEntry)
                  : ""
              }
              onSelectValue={handleChangeDropdownPorts}
            />
          </div>
          {/* <div style={{ marginLeft: 10 }}>
            <SelectDropdown
              myLabel={"Custom Place of Bagging or Blending"}
              inputName={"placeOfProcessing"}
              myId={"searchbarPOP"}
              myPlaceholder={"Select the Place of Bagging or Blending"}
              selectOptions={selectListPOP}
              isInfo="Place of Bagging/Blending"
              value={formula?.blending ? props.flagPOPtable === 1 ? props.dropdownSelectedPOP : placesOfProcessing[0].find(e => e.id === formula.blending) : ''}
              onSelectValue={handleChangeDropdownPlaceProcessing}
              completeOptions={placesOfProcessing[0]}
            />
          </div> */}
          <div style={{ marginLeft: 10 }}>
            <SelectDropdown
              myLabel={"Custom Arrival City"}
              inputName={"finalDestination"}
              myId={"searchbarFD"}
              myPlaceholder={"Select the arrival city"}
              selectOptions={selectListFD}
              value={
                formula?.destination
                  ? props.flagDEStable === 1
                    ? props.dropDownSelectedDestination
                    : finalDestinations[0].find(
                        (e) => e.id === formula.destination
                      )
                  : ""
              }
              onSelectValue={handleChangeDropdownDestination}
              completeOptions={finalDestinations[0]}
            />
          </div>
        </div>
        {buttons}
      </div>
    );
  }

  return (
    <EditProductDialog
      onSave={handleEditSave}
      customFooter={footer}
      port={portPreferences}
      dropdowns={{
        dropDownSelectedPOP: formula?.blending
          ? props.flagPOPtable === 1
            ? props.dropdownSelectedPOP
            : placesOfProcessing[0].find((e) => e.id === formula.blending)
          : "",
        dropDownSelectedPOE: formula?.portOfEntry
          ? props.flagPOEtable === 1
            ? props.dropdownSelectedPOE
            : ports[0].find((e) => e.id === formula.portOfEntry)
          : "",
        dropDownSelectedDestination: formula?.destination
          ? props.flagDEStable === 1
            ? props.dropDownSelectedDestination
            : finalDestinations[0].find((e) => e.id === formula.destination)
          : "",
      }}
    />
  );
}
