export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^((?=.*\d)(?=.*[a-zA-Z])).{8,}$/g

export const TransitModeDisplay = Object.freeze({
  NONE: 'None (Local Market)',
  DIRECT: 'Direct',
  INDIRECT: 'Indirect (Reexport)',
})

export const TransitMode = Object.freeze({
  NONE: 'NONE',
  DIRECT: 'DIRECT',
  INDIRECT: 'INDIRECT'
})
