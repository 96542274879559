import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "rc-tooltip/assets/bootstrap.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 50,
});

export const store = createStore(reducer, composeEnhancers(applyMiddleware()));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
