import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styles from './Home.module.scss';
import Header from './index.module.scss';
import UsaidLogo from '../../../assets/images/usaid_logo.png'
import UemoaLogo from '../../../assets/images/uemoa_logo.jpg'
import CilssLogo from '../../../assets/images/cilss_logo.png'
import FeedIFuture from '../../../assets/images/feedifuture.png'
import EcoWas from '../../../assets/images/ECOWAS.jpg'
import IfdcLogo from '../../../assets/images/ifdc_logo.png'
import noteAndPhone from '../../../assets/images/noteAndPhone.png'
import Tooltip from '../../../components/utils/Tooltip'
import { FaRoute, FaMoneyBillWave, FaSeedling, FaSignInAlt } from 'react-icons/fa'
import { Menubar } from 'primereact/menubar';
import { useIsAdmin } from '../../../hooks';
import bgImg from '../../../assets/images/box-truck.png'


export default function HomePage() {
  const isAdmin = useIsAdmin()

  function goToPartners() {
    var elem = document.getElementById("goToPartners");
    elem.scrollIntoView();
  }

  function goToFooter() {
    var elem = document.getElementById("goToFooter");
    elem.scrollIntoView();
  }

  function goToHowToUse() {
    var elem = document.getElementById("goToHowToUse");
    elem.scrollIntoView();
  }

  function goToHowToUseMobile() {
    var elem = document.getElementById("goToHowToUseMobile");
    elem.scrollIntoView();
  }

  function goToPartnersMobile() {
    var elem = document.getElementById("goToPartnersMobile");
    elem.scrollIntoView();
  }

  function goToFooterMobile() {
    var elem = document.getElementById("goToFooterMobile");
    elem.scrollIntoView();
  }

  const MaintenanceNotice = () => {
    const containerStyle = {
      maxWidth: '600px',
      padding: '40px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'center'
    };
  
    const backgroundStyle = {
      padding: '100px 100px 80px 100px',
      height: '100%',
      width: '100%',
      backgroundColor: '#f2f2f2',
      display: 'flex',
      justifyContent: 'center'
    };
  
    const titleStyle = {
      fontSize: '28px',
      fontWeight: '700',
      color: '#333',
      marginBottom: '20px'
    };
  
    const contentStyle = {
      fontSize: '18px',
      fontWeight: '400',
      color: '#666',
      lineHeight: '1.6'
    };
  
    return (
        <div style={backgroundStyle}>
        <div style={containerStyle}>
          <h1 style={titleStyle}>🚧🛠️ Dear visitors and users,</h1>
          <p style={contentStyle}>We'd like to inform you that your CostSimulator platform will be undergoing maintenance for greater efficiency in the coming weeks. In order to serve you better, we are currently updating our fertilizer data. We appreciate your understanding and will let you know when your tool is up and running again.</p>
        </div>
        </div>
       
    );
  }

  return (
    <>

      <div id="home" className="desktop">
        {MaintenanceNotice()}
        <div className={Styles.firstSection}>
          <div className={Styles.Container}>
            <button className="btn btn--primary" onClick={() => goToHowToUse()}>
              How to use
            </button>
            <div style={{ display: 'flex', margin: '10vh', gap: '6vh', width: '124vh' }}>
              <Tooltip overlay={"Optimize your costs from FOB to warehouse by choosing the best corridors"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
              <Tooltip overlay={"Calculate cost of production and delivery of your blended fertilizers and formulas"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
              <Tooltip overlay={"Pick the best route to deliver your fertilizers to your customers"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
            </div>
            <div style={{ display: 'flex', 'margin-top': '-28vh' }}>
              <button style={{ 'background': '#04ba56' }} className="btn btn--primary" onClick={() => goToFooter()} >
                Disclaimer
              </button>
              <button style={{ 'background': '#31c5f4' }} className="btn btn--primary" onClick={() => goToPartners()}>
                Partners
              </button>
            </div>
          </div>
        </div>
        <div id="goToHowToUse" className={Styles.thirdSection}>
          <div>
            <h1 style={{ fontSize: 50 }}>How to use</h1>
          </div>
          <div className={Styles.Container}>
            <div className={Styles.Card}>
              <Link to="formulas/compare" style={{ textDecoration: 'none', color: '#444' }}>
                <div className={Styles.iconAndStep}>
                  <FaMoneyBillWave fill='#78b943' size={44} />
                </div>
                <h2>Cost Build UP</h2>
                <div style={{ margin: 20 }}>
                  <h4>
                    1. Calculate cost of a fertilizer product
                  </h4>
                  <h4 >
                    2. Compare cost of fertilizer product
                  </h4>
                  <h4>
                    3. Compare routes for fertilizer
                  </h4>
                </div>
              </Link>
            </div>
            <div className={Styles.Card}>
              <div className={Styles.iconAndStep}>
                <FaSeedling fill='#78b943' size={44} />
              </div>
              <h2>Create Fertilizer Product</h2>
              <div style={{ margin: 20 }}>
                {isAdmin ? (
                  <>
                    <Link to="formula/create" style={{ textDecoration: 'none', color: '#444' }}>
                      <h4 className={Styles.linkHover}>
                        1. Create new blend
                      </h4>
                    </Link>
                    <Link to="/admin/productType" style={{ textDecoration: 'none', color: '#444' }}>
                      <h4 className={Styles.linkHover}>
                        2. Create raw material
                      </h4>
                    </Link>
                  </>
                ) : (
                  <>
                    <h4 className={Styles.linkHover}>
                      1. Create new blend
                    </h4>
                    <h4 className={Styles.linkHover}>
                      2. Create raw material
                    </h4>
                  </>
                )}

                <Link to="formula" style={{ textDecoration: 'none', color: '#444' }}>
                  <h4 className={Styles.linkHover}>
                    3. Modify product
                  </h4>
                </Link>
              </div>

            </div>
            <div class={Styles.Card}>
              <div className={Styles.iconAndStep}>
                <FaRoute fill='#78b943' size={44} />
              </div>
              <h2 >Set Up Cost Parameters</h2>
              <div style={{ margin: 20 }}>
                <Link to="/admin/finalDestination" style={{ textDecoration: 'none', color: '#444' }}>
                  <h4 className={Styles.linkHover}>
                    1. Set up location parameters
                  </h4>
                </Link>
                <Link to="/admin/landTransport" style={{ textDecoration: 'none', color: '#444' }}>
                  <h4 className={Styles.linkHover}>
                    2. Set up cost parameters
                  </h4>
                </Link>
                <Link to="/admin/portCharge" style={{ textDecoration: 'none', color: '#444' }}>
                  <h4 className={Styles.linkHover}>
                    3. Set up raw material parameters
                  </h4>
                </Link>
              </div>

            </div>
          </div>
          <div className={Styles.ViewButton}>
            <button
              style={{
                'font-weight': 'bold',
                'max-width': '250px',
                'background': '#31c5f4'
              }}
              className="btn btn--primary" >
              <a style={{ color: '#ffffff', textDecoration: 'none' }}>  Request demo or assistance</a>

            </button>
          </div>
        </div>
        <div className={Styles.secondSection}>
          <img src={noteAndPhone}
            className={Styles.noteAndPhone}
            alt=""
          />
          <div style={{ width: "50%" }}>
            <h1>West Africa Fertilizer Cost Simulator</h1>
            <p>A tool designed to assist in evaluating the cost of fertilizers from port to farm and Select the most adequate logistical options!</p>

          </div>
        </div>

        <div id="goToPartners" className={Styles.Partners}>
          <h1 style={{ fontSize: 50 }}>This project is supported by</h1>
          <div className={Styles.Container}>
            <div className={Styles.Card}>
              <div class={Styles.BoxImage}>
                <img src={FeedIFuture} style={{ width: "45vh" }} alt="" />
              </div>
              <div className={Styles.BoxImage}>
                <img src={EcoWas} style={{ width: "35vh" }} alt="" />
              </div>
            </div>
            <div className={Styles.Card}>
              <div className={Styles.BoxImage}>
                <img src={UsaidLogo} style={{ width: "40vh" }} alt="" />
              </div>
              <div class={Styles.BoxImage}>
                <img src={UemoaLogo} style={{ width: "16vh" }} alt="" />
              </div>
            </div>
            <div className={Styles.Card}>
              <div className={Styles.BoxImage}>
                <img src={IfdcLogo} style={{ width: "30vh" }} alt="" />
              </div>
              <div className={Styles.BoxImage}>
                <img src={CilssLogo} style={{ width: "20vh" }} alt="" />
              </div>
            </div>
          </div>
        </div>
        <footer id="goToFooter" className={Styles.footer} >
          <div className={Styles.Container}>
            <div className={Styles.infosFooter}>
              <img src={IfdcLogo} style={{ width: "30vh" }} alt="" />
              <div>
                <p>This tool was developed with funding from USAID through Feed the Future, the U.S. Government’s Global Hunger and Food Security Initiative. The contents are the responsibility of IFDC and do not necessarily reflect the views of Feed the Future or the United States Government.</p>
              </div>
            </div>
            <div className={Styles.copyrights}>
              <div>
                Copyright © IFDC 2022 | <a href="https://ifdc.org/privacy-policy/" target="_blank" style={{ textDecoration: 'none' }}>Privacy Policy</a>
              </div>
              <div>
                Developed by <a href="https://codescript.com.br/" target="_blank" style={{ textDecoration: 'none' }}>CodeScript Technology</a>
              </div>
            </div>
          </div>
        </footer >
      </div >
      <div id="home" className="mobile">
        <div className={Styles.firstSection}>
          <img style={{ marginTop: 30 }} src={bgImg} alt="" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '20px' }}>West Africa</p>
            <h1>Fertilizer Cost Simulator</h1>
          </div>
          <div className={Styles.Container}>
            <div className={Styles.Buttons}>
              <button className="btn btn--primary" onClick={() => goToHowToUseMobile()}>
                How to use
              </button>
              <button style={{ 'background': '#04ba56' }} className="btn btn--primary" onClick={() => goToFooterMobile()} >
                Disclaimer
              </button>
              <button style={{ 'background': '#31c5f4' }} className="btn btn--primary" onClick={() => goToPartnersMobile()}>
                Partners
              </button>
            </div>
          </div>
          {/* <div style={{display: 'flex'}}>
            <Tooltip overlay={"Optimize your costs from FOB to warehouse by choosing the best corridors"} placement="bottom">
              <div style={{ width: '31vh', height: '31vh' }}></div>
            </Tooltip>
            <Tooltip overlay={"Calculate cost of production and delivery of your blended fertilizers and formulas"} placement="bottom">
              <div style={{ width: '31vh', height: '31vh' }}></div>
            </Tooltip>
            <Tooltip overlay={"Pick the best route to deliver your fertilizers to your customers"} placement="bottom">
              <div style={{ width: '31vh', height: '31vh' }}></div>
            </Tooltip>
          </div> */}
        </div>

        <div id="goToHowToUseMobile" className={Styles.thirdSection}>
          <div className={Styles.Container}>
            <h1 style={{ fontSize: '45px' }}>How to use</h1>
            <div className={Styles.Card} >
              <Link to="formulas/compare" style={{ textDecoration: 'none', color: '#444', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }}>
                <div className={Styles.iconAndStep}>
                  <FaMoneyBillWave fill='#78b943' size={44} />
                </div>
                <h2>Cost Build UP</h2>
                <div>
                  <h4>
                    1. Calculate cost of a fertilizer product
                  </h4>
                  <h4 >
                    2. Compare cost of fertilizer product
                  </h4>
                  <h4>
                    3. Compare routes for fertilizer
                  </h4>
                </div>
              </Link>
            </div>
            <div className={Styles.Card}>
              <div className={Styles.iconAndStep}>
                <FaSeedling fill='#78b943' size={44} />
              </div>
              <h2>Create Fertilizer Product</h2>
              {isAdmin ? (
                <>
                  <Link to="formula/create" style={{ textDecoration: 'none', color: '#444' }}>
                    <h4 className={Styles.linkHover}>
                      1. Create new blend
                    </h4>
                  </Link>
                  <Link to="/admin/productType" style={{ textDecoration: 'none', color: '#444' }}>
                    <h4 className={Styles.linkHover}>
                      2. Create raw material
                    </h4>
                  </Link>
                </>
              ) : (
                <>
                  <h4 className={Styles.linkHover}>
                    1. Create new blend
                  </h4>
                  <h4 className={Styles.linkHover}>
                    2. Create raw material
                  </h4>
                </>
              )}

              <Link to="formula" style={{ textDecoration: 'none', color: '#444' }}>
                <h4 className={Styles.linkHover}>
                  3. Modify product
                </h4>
              </Link>
            </div>
            <div class={Styles.Card}>
              <div className={Styles.iconAndStep}>
                <FaRoute fill='#78b943' size={44} />
              </div>
              <h2 >Set Up Cost Parameters</h2>
              <div>
                <Link to="/admin/finalDestination" style={{ textDecoration: 'none', color: '#444' }}>
                  <h4 className={Styles.linkHover}>
                    1. Set up location parameters
                  </h4>
                </Link>
                <Link to="/admin/landTransport" style={{ textDecoration: 'none', color: '#444' }}>
                  <h4 className={Styles.linkHover}>
                    2. Set up cost parameters
                  </h4>
                </Link>
                <Link to="/admin/portCharge" style={{ textDecoration: 'none', color: '#444' }}>
                  <h4 className={Styles.linkHover}>
                    3. Set up raw material parameters
                  </h4>
                </Link> </div>

            </div>
            <div className={Styles.ViewButton}>
              <button
                style={{
                  'font-weight': 'bold',
                  'max-width': '250px',
                  'background': '#31c5f4',
                  'margin-top': '50px',
                }}
                className="btn btn--primary" >
                <a style={{ color: '#ffffff', textDecoration: 'none' }}>  Request demo or assistance</a>
              </button>
            </div>
          </div>
        </div>
        <div className={Styles.secondSection}>
          <img src={noteAndPhone}
            className={Styles.noteAndPhone}
            alt=""
          />
          <div style={{ width: "50%" }}>
            <h1>West Africa Fertilizer Cost Simulator</h1>
            <p>A tool designed to assist in evaluating the cost of fertilizers from port to farm and Select the most adequate logistical options!</p>
            <Link to="/register">
              <button
                style={{
                  'font-weight': 'bold',
                  'margin-top': '30px',
                  'background': '#F5740C'
                }}
                className="btn btn--primary" >
                Sign Up
              </button>
            </Link>
          </div>
        </div>
        <div id="goToPartnersMobile" className={Styles.Partners}>
          <h1>This project is <b>supported by:</b></h1>
          <div className={Styles.Container}>
            <div class={Styles.BoxImage}>
              <img src={FeedIFuture} alt="" />
            </div>
            <div className={Styles.BoxImage}>
              <img src={EcoWas} alt="" />
            </div>
            <div className={Styles.BoxImage}>
              <img src={UsaidLogo} alt="" />
            </div>
            <div class={Styles.BoxImage}>
              <img src={UemoaLogo} alt="" />
            </div>
            <div className={Styles.BoxImage}>
              <img src={IfdcLogo} alt="" />
            </div>
            <div className={Styles.BoxImage}>
              <img src={CilssLogo} alt="" />
            </div>
          </div>
        </div>
        <footer id="goToFooterMobile" className={Styles.footer} >
          <div className={Styles.Container}>
            <div className={Styles.infosFooter}>
              <img src={IfdcLogo} style={{ width: "30vh" }} alt="" />
              <div>
                <p>This tool was developed with funding from USAID through Feed the Future, the U.S. Government’s Global Hunger and Food Security Initiative. The contents are the responsibility of IFDC and do not necessarily reflect the views of Feed the Future or the United States Government.</p>
              </div>
            </div>
            <div className={Styles.copyrights}>
              <div>
                Copyright © IFDC 2022 | <a href="https://ifdc.org/privacy-policy/" target="_blank" style={{ textDecoration: 'none' }}>Privacy Policy</a>
              </div>
              <div>
                Developed by <a href="https://codescript.com.br/" target="_blank" style={{ textDecoration: 'none' }}>CodeScript Technology</a>
              </div>
            </div>
          </div>
        </footer >
      </div>
    </>
  )
}
