import { Types } from '../actions';
import { TransitMode } from '../../const';

export const productModel = Object.freeze({
  type: 1,
  color: '#0000FF',
  transit: TransitMode.NONE,
  minFob: 0,
  maxFob: 0,
  percentage: 0,
  seaFreight: 0,
  storageDuration: 0,
  storageCost: 0,
  loanCashoutDuration: 0,
  bankInterestRate: 0,
  demurrageDuration: 0,
  demurragePrice: 0,
  demurrage: 0,
  placeOfBlending: 1,
  passageMode: 1,
  port: null
})

const defaultState = Object.freeze({
  id: undefined,
  name: "Unnamed Formula",
  public: false,
  products: [ ],
  orderFormula: undefined,
})

const createProduct = (minFob = 0, maxFob = 0, percentage = 0) => ({
  ...productModel,
  color: '#' + Math.floor(Math.random() * 16777215).toString(16).toUpperCase().padEnd(6, '0'),
  minFob,
  maxFob,
  percentage
})

export default (state = defaultState, action) => {
  switch (action.type) {

    case Types.LOAD_FORMULA: {
      return {
        ...defaultState,
        id: action.payload.id,
        name: action.payload.name,
        public: action.payload.public,
        products: action.payload.products.map(product => ({ ...createProduct(), ...product })),
        orderFormula: action.payload.orderFormula
      }
    }

    case Types.CLEAR_FORMULA: {
      return { ...defaultState }
    }

    case Types.UPDATE_NAME: {
      return {
        ...state,
        name: action.payload
      }
    }

    case Types.UPDATE_ID: {
      return {
        ...state,
        id: action.payload
      }
    }

    case Types.CREATE_PRODUCT: {
      const percentageSum = state.products.reduce((acc, it) => acc + it.percentage, 0)
      const percentage = Math.min(Math.max(100 - percentageSum, 0), 100);
      return {
        ...state,
        products: [
          ...state.products,
          { ...createProduct(action.payload.minFob, action.payload.maxFob, percentage) }
        ]
      }
    }

    case Types.DELETE_PRODUCT: {
      return {
        ...state,
        products: state.products.filter((_, index) => index !== action.payload)
      }
    }

    case Types.UPDATE_PRODUCT: {
      return {
        ...state,
        products: state.products.map((product, index) => {
          if (index === action.payload.index) {
            return { ...product, ...action.payload.data }
          } else {
            return product
          }
        })
      }
    }

    case Types.UPDATE_VISIBILITY: {
      return {
        ...state,
        public: !!action.payload
      }
    }

    default: return state
  }
}
