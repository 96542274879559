import React from 'react';
import { Dialog } from 'primereact/dialog';
import copyToClipboard from 'copy-to-clipboard';
import { InputText } from 'primereact/inputtext';
import toast, { Toaster } from 'react-hot-toast';
import XIcon from '../../../assets/images/XIcon.svg'
import ReactLoading from 'react-loading';
import Tooltip from '../../utils/Tooltip';

export default function ShareDialog({ visible, setVisible, dropdownSelectedPOE, dropdownSelectedPOP, dropDownSelectedDestination, shareId }) {

  const [copied, setCopied] = React.useState(false)

  function renderCopy() {
    let search = `&entry=${dropdownSelectedPOE?.id ? dropdownSelectedPOE.id: ''}&processing=${dropdownSelectedPOP?.id ? dropdownSelectedPOP.id : ''}&destination=${dropDownSelectedDestination?.id ? dropDownSelectedDestination.id: ''}`
    const url = `${window.location.origin}/my/formulas/compare/shared?id=${shareId}${search}`


    function copy() {
      toast.success('Copied to clipboard')
      setCopied(true)
      copyToClipboard(url)
    }

    return (
      <>
        <label style={{ fontWeight: 'bold' }}>Copy to Clipboard:</label>
        <button onClick={() => hide()} className="buttonCloseSharing">
          <img src={XIcon} alt="X" />
        </button>
        <div className="p-inputgroup">
          <InputText contentEditable={false} value={url} style={{ fontSize: '.75rem' }}/>
          <Tooltip overlay={copied && "Copied!"} placement='left' trigger='hover' zIndex={999999}>
            <span className="p-inputgroup-addon" style={{ cursor: 'pointer', display: 'inline', backgroundColor: copied ? '#04ba56' : ''}} onClick={() => copy()}>
              <i className="fa fa-copy" style={{ 
                transition: 'backgroundColor 0.2s',
                color: copied ? '#ffffff' : ''
              }}
              />
            </span>
          </Tooltip>
        </div>
      </>
    )
  }

  function hide() {
    setVisible(false)
  }

  return (
    <Dialog
      header={shareId ? "Comparison ready to be shared! 😎" : 'Creating shareable link...'}
      onHide={hide}
      visible={visible}
      modal
      draggable={false}
    >
      { shareId ? renderCopy() : 
        <div className="dfCenter">
          <ReactLoading type={'spinningBubbles'} color={'#31c5f4'} height={'40px'} width={'40px'}/>
        </div>
      }
    </Dialog>
  )
}
