import { Types } from '../actions/';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const defaultState = Object.freeze({
  currency: 'USD',
  USD: cookies.get('USD') === undefined ? 1 : cookies.get('USD'),
  fcfa: cookies.get('FCFA') === undefined ? 580 : cookies.get('FCFA'),
  ghs: cookies.get('GHS') === undefined ? 5.51 : cookies.get('GHS'),
  ngn: cookies.get('NGN') === undefined ? 429.87 : cookies.get('NGN')
})

export default (state = defaultState, action) => {
  switch (action.type) {

    case Types.SET_CURRENCY: return {
      ...state,
      currency: action.payload
    }

    case Types.SET_FCFA: return {
      ...state,
      fcfa: action.payload
    }

    case Types.SET_GHS: return {
      ...state,
      ghs: action.payload
    }

    case Types.SET_NGN: return {
      ...state,
      ngn: action.payload
    }

    case Types.RESET_FCFA: return {
      ...state,
      fcfa: defaultState.fcfa
    }

    case Types.RESET_GHS: return {
      ...state,
      ghs: defaultState.ghs
    }

    case Types.RESET_NGN: return {
      ...state,
      ngn: defaultState.ngn
    }

    default: return state
  }
}
