import axios from 'axios';

const baseURL =  process.env.REACT_APP_API_BASE_URL

export const create = (token) => {

  const api = axios.create({
    baseURL,
    headers: {
      Authorization: token
    }
  })

  const isAdmin = () => api.get('/auth/admin')

  const getFinalDestinations = () => api.get('/finalDestination')
  const getFinalDestination = (id) => api.get(`/finalDestination/${id}`)
  const addFinalDestination = (payload) => api.post('/finalDestination', payload)
  const deleteFinalDestination = (id) => api.delete(`/finalDestination/${id}`)
  const editFinalDestination = (id, payload) => api.put(`/finalDestination/${id}`, payload)

  const getPorts = () => api.get('/port')
  const getPort = (id) => api.get(`/port/${id}`)
  const addPort = (payload) => api.post('/port', payload)
  const deletePort = (id) => api.delete(`/port/${id}`)
  const editPort = (id, payload) => api.put(`/port/${id}`, payload)

  const getProductTypes = () => api.get('/productType')
  const getProductType = (id) => api.get(`/productType/${id}`)
  const addProductType = (payload) => api.post('/productType', payload)
  const deleteProductType = (id) => api.delete(`/productType/${id}`)
  const editProductType = (id, payload) => api.put(`/productType/${id}`, payload)

  //const getSearchFormulas = (id) => api.get(`/productType?id=${id}`)
 /*  const getSearchFormula = (id) => api.get(`/productType/${id}`) */
 /*  const addProductType = (payload) => api.post('/productType', payload)
  const deleteProductType = (id) => api.delete(`/productType/${id}`)
  const editProductType = (id, payload) => api.put(`/productType/${id}`, payload) */

  const getPassageModes = () => api.get('/passageMode')
  const getPassageMode = (id) => api.get(`/passageMode/${id}`)
  const addPassageMode = (payload) => api.post('/passageMode', payload)
  const deletePassageMode = (id) => api.delete(`/passageMode/${id}`)
  const editPassageMode = (id, payload) => api.put(`/passageMode/${id}`, payload)

  const getCountries = () => api.get('/country')
  const getCountry = (id) => api.get(`/country/${id}`)
  const addCountry = (payload) => api.post('/country', payload)
  const deleteCountry = (id) => api.delete(`/country/${id}`)
  const editCountry = (id, payload) => api.put(`/country/${id}`, payload)

  const getTowns = () => api.get('/town')
  const getTown = (id) => api.get(`/town/${id}`)
  const addTown = (payload) => api.post('/town', payload)
  const deleteTown = (id) => api.delete(`/town/${id}`)
  const editTown = (id, payload) => api.put(`/town/${id}`, payload)

  const getPortCharges = () => api.get('/portCharge')
  const getPortCharge = (id) => api.get(`/portCharge/${id}`)
  const addPortCharge = (payload) => api.post('/portCharge', payload)
  const deletePortCharge = (id) => api.delete(`/portCharge/${id}`)
  const editPortCharge = (id, payload) => api.put(`/portCharge/${id}`, payload)

  const getLandTransports = () => api.get('/landTransport')
  const getLandTransport = (id) => api.get(`/landTransport/${id}`)
  const addLandTransport = (payload) => api.post('/landTransport', payload)
  const deleteLandTransport = (id) => api.delete(`/landTransport/${id}`)
  const editLandTransport = (id, payload) => api.put(`/landTransport/${id}`, payload)

  return {
    api,
    isAdmin,
    getFinalDestinations,
    getFinalDestination,
    addFinalDestination,
    deleteFinalDestination,
    editFinalDestination,
    getPorts,
    getPort,
    addPort,
    deletePort,
    editPort,
    getProductTypes,
    getProductType,
    addProductType,
    deleteProductType,
    editProductType,
    getPassageModes,
    getPassageMode,
    addPassageMode,
    deletePassageMode,
    editPassageMode,
    getCountries,
    getCountry,
    addCountry,
    editCountry,
    deleteCountry,
    getTowns,
    getTown,
    addTown,
    editTown,
    deleteTown,
    getPortCharges,
    getPortCharge,
    addPortCharge,
    editPortCharge,
    deletePortCharge,
    getLandTransports,
    getLandTransport,
    editLandTransport,
    addLandTransport,
    deleteLandTransport
  };
}
