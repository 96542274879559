export const MinMax = {
  MIN: 'MIN',
  MAX: 'MAX'
}

export function mkPair(min, max) {
  return {
    [MinMax.MIN]: min,
    [MinMax.MAX]: max,
    get: (mode) => mode === MinMax.MIN ? min : max
  }
}
