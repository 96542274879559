import React from 'react';

export default function FloatLabel({ id, label, children }) {

  const onRefChanged = React.useCallback((ref) => {
    if (ref) {
      [...ref.children, ...ref.querySelectorAll('input')].forEach(children => children.id = id)
    }
  })

  return (
    <div style={{ marginTop: 20 }}>
      <span className="p-float-label" ref={onRefChanged}>
        { children }
        <label htmlFor={id}>{label}</label>
      </span>
    </div>
  )
}
