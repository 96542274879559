import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './Home.module.scss';
import Header from './index.module.scss';
import UsaidLogo from '../../../assets/images/usaid_logo.png'
import UemoaLogo from '../../../assets/images/uemoa_logo.jpg'
import CilssLogo from '../../../assets/images/cilss_logo.png'
import FeedIFuture from '../../../assets/images/feedifuture.png'
import bgImg from '../../../assets/images/box-truck.png'
import EcoWas from '../../../assets/images/ECOWAS.jpg'
import IfdcLogo from '../../../assets/images/ifdc_logo.png'
import noteAndPhone from '../../../assets/images/noteAndPhone.png'
import Tooltip from '../../../components/utils/Tooltip'
import { FaRoute, FaMoneyBillWave, FaSeedling, FaSignInAlt } from 'react-icons/fa'
import { Menubar } from 'primereact/menubar';

export default function HomePage() {

  function goToPartners() {
    var elem = document.getElementById("goToPartners");
    elem.scrollIntoView();
  }

  function goToFooter() {
    var elem = document.getElementById("goToFooter");
    elem.scrollIntoView();
  }

  function goToHowToUse() {
    var elem = document.getElementById("goToHowToUse");
    elem.scrollIntoView();
  }

  const menuModel = [
    {
      label: 'Home',
    },
  ]

  const menubarStart =
    <nav className="menuStart noPrint">
      <img className={Header.logoNavbar} alt="IFDC Logo" src="/logo.png" />
    </nav>

  const menubarEnd =
    <div className={Styles.menuBarEnd}>
      <select style={{width: '100px'}} className="translate" id="translate">
        <option value="english">English</option>
        <option value="french">French</option>
        <option value="portuguese">Portuguese</option>
      </select>
      <nav className={Header.MenubarEnd} style={{ marginLeft: 20, marginTop: 5, display: 'flex' }}>
        <Link to="/login">
          <FaSignInAlt size={24} fill="#555555" />
        </Link>
      </nav>
    </div>

  return (
    <>
      <div id="home" className="desktop">
        <Menubar className={Header.Header} model={menuModel} start={menubarStart} end={menubarEnd} />
        <div className={Styles.firstSection}>
          <div className={Styles.Container}>
            <button className="btn btn--primary" onClick={() => goToHowToUse()}>
              How to use
            </button>
            <div style={{ display: 'flex', margin: '10vh', gap: '6vh', width: '124vh' }}>
              <Tooltip overlay={"Optimize your costs from FOB to warehouse by choosing the best corridors"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
              <Tooltip overlay={"Calculate cost of production and delivery of your blended fertilizers and formulas"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
              <Tooltip overlay={"Pick the best route to deliver your fertilizers to your customers"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
            </div>
            <div style={{ display: 'flex', 'margin-top': '-28vh' }}>
              <button style={{ 'background': '#04ba56' }} className="btn btn--primary" onClick={() => goToFooter()} >
                Disclaimer
              </button>
              <button style={{ 'background': '#31c5f4' }} className="btn btn--primary" onClick={() => goToPartners()}>
                Partners
              </button>
            </div>
          </div>
        </div>

        <div id="goToHowToUse" className={Styles.thirdSection}>
          <div>
            <h1 style={{ fontSize: 50 }}>How to use</h1>
          </div>
          <div className={Styles.Container}>
            <div className={Styles.Card} >
              <div className={Styles.iconAndStep}>
                <FaMoneyBillWave fill='#78b943' size={44} />
              </div>
              <h2>Cost Build UP</h2>
              <div style={{ margin: 20 }}>
                <h4>
                  1. Calculate cost of a fertilizer product
                </h4>
                <h4 >
                  2. Compare cost of fertilizer product
                </h4>
                <h4>
                  3. Compare routes for fertilizer
                </h4>
              </div>
            </div>
            <div className={Styles.Card}>
              <div className={Styles.iconAndStep}>
                <FaSeedling fill='#78b943' size={44} />
              </div>
              <h2>Create Fertilizer Product</h2>
              <div style={{ margin: 20 }}>
                <h4>
                  1. Create new blend
                </h4>
                <h4>
                  2. Create raw material
                </h4>
                <h4>
                  3. Modify product
                </h4>
              </div>
            </div>
            <div class={Styles.Card}>
              <div className={Styles.iconAndStep}>
                <FaRoute fill='#78b943' size={44} />
              </div>
              <h2 >Set Up Cost Parameters</h2>
              <div style={{ margin: 20 }}>
                <h4>
                  1. Set up location parameters
                </h4>
                <h4>
                  2. Set up cost parameters
                </h4>
                <h4>
                  3. Set up raw material parameters
                </h4>
              </div>

            </div>
          </div>
          <div className={Styles.ViewButton}>
            <button
              style={{
                'font-weight': 'bold',
                'max-width': '250px',
                'background': '#31c5f4'
              }}
              className="btn btn--primary" >
              <a style={{ color: '#ffffff', textDecoration: 'none' }}>  Request demo or assistance</a>

            </button>
          </div>
        </div>
        <div className={Styles.secondSection}>
          <img src={noteAndPhone}
            className={Styles.noteAndPhone}
            alt=""
          />
          <div style={{ width: "50%" }}>
            <h1>West Africa Fertilizer Cost Simulator</h1>
            <p>A tool designed to assist in evaluating the cost of fertilizers from port to farm and Select the most adequate logistical options!</p>
            <Link to="/register">
              <button
                style={{
                  'font-weight': 'bold',
                  'margin-top': '10px',
                  'max-width': '150px',
                  'background': '#F5740C'
                }}
                className="btn btn--primary" >
                Sign Up
              </button>
            </Link>
          </div>
        </div>

        <div id="goToPartners" className={Styles.Partners}>
          <h1 style={{ fontSize: 50 }}>This project is supported by</h1>
          <div className={Styles.Container}>
            <div className={Styles.Card}>
              <div class={Styles.BoxImage}>
                <img src={FeedIFuture} style={{ width: "45vh" }} alt="" />
              </div>
              <div className={Styles.BoxImage}>
                <img src={EcoWas} style={{ width: "35vh" }} alt="" />
              </div>
            </div>
            <div className={Styles.Card}>
              <div className={Styles.BoxImage}>
                <img src={UsaidLogo} style={{ width: "40vh" }} alt="" />
              </div>
              <div class={Styles.BoxImage}>
                <img src={UemoaLogo} style={{ width: "16vh" }} alt="" />
              </div>
            </div>
            <div className={Styles.Card}>
              <div className={Styles.BoxImage}>
                <img src={IfdcLogo} style={{ width: "30vh" }} alt="" />
              </div>
              <div className={Styles.BoxImage}>
                <img src={CilssLogo} style={{ width: "20vh" }} alt="" />
              </div>
            </div>
          </div>
        </div>
        <footer id="goToFooter" className={Styles.footer} >
          <div className={Styles.Container}>
            <div className={Styles.infosFooter}>
              <img src={IfdcLogo} style={{ width: "30vh" }} alt="" />
              <div>
                <p>This tool was developed with funding from USAID through Feed the Future, the U.S. Government’s Global Hunger and Food Security Initiative. The contents are the responsibility of IFDC and do not necessarily reflect the views of Feed the Future or the United States Government.</p>
              </div>
            </div>
            <div className={Styles.copyrights}>
              <div>
                Copyright © IFDC 2022 | <a href="https://ifdc.org/privacy-policy/" target="_blank" style={{ textDecoration: 'none' }}>Privacy Policy</a>
              </div>
              <div>
                Developed by <a href="https://codescript.com.br/" target="_blank" style={{ textDecoration: 'none' }}>CodeScript Technology</a>
              </div>
            </div>
          </div>
        </footer >
      </div>
      <div id="home" className="mobile">
        <Menubar className={Header.Header} model={menuModel} start={menubarStart} end={menubarEnd} />
        <div className={Styles.firstSection}>
          <img src={bgImg} alt="" />
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{fontSize: '20px'}}>West Africa</p>
            <h1>Fertilizer Cost Simulator</h1>
          </div>
          <div className={Styles.Container}>
            <div className={Styles.Buttons}>
              <button className="btn btn--primary" onClick={() => goToHowToUse()}>
                How to use
              </button>
              <button style={{ 'background': '#04ba56' }} className="btn btn--primary" onClick={() => goToFooter()} >
                Disclaimer
              </button>
              <button style={{ 'background': '#31c5f4' }} className="btn btn--primary" onClick={() => goToPartners()}>
                Partners
              </button>
            </div>
          </div>
          {/* <div style={{display: 'flex'}}>
              <Tooltip overlay={"Optimize your costs from FOB to warehouse by choosing the best corridors"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
              <Tooltip overlay={"Calculate cost of production and delivery of your blended fertilizers and formulas"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
              <Tooltip overlay={"Pick the best route to deliver your fertilizers to your customers"} placement="bottom">
                <div style={{ width: '31vh', height: '31vh' }}></div>
              </Tooltip>
            </div> */}
        </div>

        <div id="goToHowToUse" className={Styles.thirdSection}>
          <div className={Styles.Container}>
            <h1 style={{ fontSize: '45px' }}>How to use</h1>
            <div className={Styles.Card} >
              <div className={Styles.iconAndStep}>
                <FaMoneyBillWave fill='#78b943' size={44} />
              </div>
              <h2>Cost Build UP</h2>
              <div>
                <h4>
                  1. Calculate cost of a fertilizer product
                </h4>
                <h4 >
                  2. Compare cost of fertilizer product
                </h4>
                <h4>
                  3. Compare routes for fertilizer
                </h4>
              </div>
            </div>
            <div className={Styles.Card}>
              <div className={Styles.iconAndStep}>
                <FaSeedling fill='#78b943' size={44} />
              </div>
              <h2>Create Fertilizer Product</h2>
              <div>
                <h4>
                  1. Create new blend
                </h4>
                <h4>
                  2. Create raw material
                </h4>
                <h4>
                  3. Modify product
                </h4>
              </div>
            </div>
            <div class={Styles.Card}>
              <div className={Styles.iconAndStep}>
                <FaRoute fill='#78b943' size={44} />
              </div>
              <h2 >Set Up Cost Parameters</h2>
              <div>
                <h4>
                  1. Set up location parameters
                </h4>
                <h4>
                  2. Set up cost parameters
                </h4>
                <h4>
                  3. Set up raw material parameters
                </h4>
              </div>
              <div className={Styles.ViewButton}>
                <button
                  style={{
                    'font-weight': 'bold',
                    'max-width': '250px',
                    'background': '#31c5f4',
                    'margin-top': '50px',
                  }}
                  className="btn btn--primary" >
                  <a style={{ color: '#ffffff', textDecoration: 'none' }}>  Request demo or assistance</a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.secondSection}>
          <img src={noteAndPhone}
            className={Styles.noteAndPhone}
            alt=""
          />
          <div style={{ width: "50%" }}>
            <h1>West Africa Fertilizer Cost Simulator</h1>
            <p>A tool designed to assist in evaluating the cost of fertilizers from port to farm and Select the most adequate logistical options!</p>
            <Link to="/register">
              <button
                style={{
                  'font-weight': 'bold',
                  'margin-top': '30px',
                  'background': '#F5740C'
                }}
                className="btn btn--primary" >
                Sign Up
              </button>
            </Link>
          </div>
        </div>
        <div id="goToPartners" className={Styles.Partners}>
          <h1>This project is <b>supported by:</b></h1>
          <div className={Styles.Container}>
            <div class={Styles.BoxImage}>
              <img src={FeedIFuture} alt="" />
            </div>
            <div className={Styles.BoxImage}>
              <img src={EcoWas} alt="" />
            </div>
            <div className={Styles.BoxImage}>
              <img src={UsaidLogo} alt="" />
            </div>
            <div class={Styles.BoxImage}>
              <img src={UemoaLogo} alt="" />
            </div>
            <div className={Styles.BoxImage}>
              <img src={IfdcLogo} alt="" />
            </div>
            <div className={Styles.BoxImage}>
              <img src={CilssLogo} alt="" />
            </div>
          </div>
        </div>
        <footer id="goToFooter" className={Styles.footer} >
          <div className={Styles.Container}>
            <div className={Styles.infosFooter}>
              <img src={IfdcLogo} style={{ width: "30vh" }} alt="" />
              <div>
                <p>This tool was developed with funding from USAID through Feed the Future, the U.S. Government’s Global Hunger and Food Security Initiative. The contents are the responsibility of IFDC and do not necessarily reflect the views of Feed the Future or the United States Government.</p>
              </div>
            </div>
            <div className={Styles.copyrights}>
              <div>
                Copyright © IFDC 2022 | <a href="https://ifdc.org/privacy-policy/" target="_blank" style={{ textDecoration: 'none' }}>Privacy Policy</a>
              </div>
              <div>
                Developed by <a href="https://codescript.com.br/" target="_blank" style={{ textDecoration: 'none' }}>CodeScript Technology</a>
              </div>
            </div>
          </div>
        </footer >
      </div>
    </>
  )
}
