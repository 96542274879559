import React from 'react';
import { Card } from 'primereact/card';
import { auth } from '../../../services/firebase';
import Styles from './EmailNotVerified.module.scss';
export default function EmailNotVerified() {

  const signOut = () => auth.signOut()

  return (
    <main className={Styles.EmailScreen}>
      <Card className={Styles.EmailNotVerified}>
        <h1>Please verify your e-mail</h1>
        <p className={Styles.Subtitle}>We sent a verification link to your e-mail address. Please check your inbox</p>
        <p>You are not <span className={Styles.Email}>{auth.currentUser.email}</span>? <a href="/login" onClick={signOut}>Sign Out</a></p>
        <div className="WrapOr">
        <div className="afterBefore orGoogle">Or</div>
        </div>
        <p className={Styles.SignIn}>Already have an account? <a href="/login" onClick={signOut}>Sign In</a></p>
      </Card>
    </main>
  )
}
