import { productModel } from '../reducers/formulaReducer';

export const Types = Object.freeze({
  CLEAR_FORMULA: 'FORMULA/CLEAR_FORMULA',
  LOAD_FORMULA: 'FORMULA/LOAD_FORMULA',
  UPDATE_NAME: 'FORMULA/UPDATE_NAME',
  UPDATE_ID: 'FORMULA/UPDATE_ID',
  CREATE_PRODUCT: 'FORMULA/CREATE_PRODUCT',
  DELETE_PRODUCT: 'FORMULA/DELETE_PRODUCT',
  UPDATE_PRODUCT: 'FORMULA/UPDATE_PRODUCT',
  UPDATE_VISIBILITY: 'FORMULA/UPDATE_VISIBILITY',

  UI_EDITING_PRODUCT: 'UI/EDITING_PRODUCT',

  SET_CURRENCY: 'CURRENCY/SET_CURRENCY',
  SET_FCFA: 'CURRENCY/SET_FCFA',
  SET_GHS: 'CURRENCY/SET_GHS',
  SET_NGN: 'CURRENCY/SET_NGN',
  RESET_FCFA: 'CURRENCY/RESET_FCFA',
  RESET_GHS: 'CURRENCY/RESET_GHS',
  RESET_NGN: 'CURRENCY/RESET_NGN',

  COMP_CLEAR: 'COMPARE/CLEAR',
  COMP_ADD_FORMULA: 'COMPARE/ADD_FORMULA',
  COMP_RM_FORMULA: 'COMPARE_RM_FORMULA',
  COMP_RM_INDEX: 'COMP_RM_INDEX',
  COMP_UPDATE_PRODUCTS: 'COMPARE/UPDATE_PRODUCTS',
  COMP_UPDATE_BLENDING: 'COMPARE/UPDATE_BLENDING',
  COMP_UPDATE_DESTINATION: 'COMPARE/UPDATE_DESTINATION',
  COMP_UPDATE_PORTS: 'COMPARE/COMP_UPDATE_PORTS',
  COMP_EDIT_FORMULA: 'COMPARE/EDIT_FORMULA',

  SET_MENUSELECTED: 'MENUHEADER/SET_MENUSELECTED',
})

export const clearFormula = () => ({
  type: Types.CLEAR_FORMULA
})

export const loadFormula = (id, name, products, isPublic, orderFormula) => ({
  type: Types.LOAD_FORMULA,
  payload: {
    id, name, products, public: isPublic, orderFormula
  }
})

export const updateName = (name) => ({
  type: Types.UPDATE_NAME,
  payload: name
})

export const createProduct = (minFob, maxFob) => ({
  type: Types.CREATE_PRODUCT,
  payload: { minFob, maxFob }
})

export const deleteProduct = (index) => ({
  type: Types.DELETE_PRODUCT,
  payload: index
})

export const updateProduct = (index, data) => {
  //console.log("index", index, data)
  const modelKeys = Object.keys(productModel)
  //console.log("modelKeys", modelKeys)

  const validatedData = Object.fromEntries(
    Object.entries(data).filter(([key]) => {
      return modelKeys.includes(key)
    })
  )

  return {
    type: Types.UPDATE_PRODUCT,
    payload: {
      index: index,
      data: data
    }
  }
}

export const updateVisibility = (isPublic) => ({
  type: Types.UPDATE_VISIBILITY,
  payload: isPublic
})

export const uiEditProduct = (index) => ({
  type: Types.UI_EDITING_PRODUCT,
  payload: index
})

export const uiStopEditProduct = () => uiEditProduct(null)

export const setCurrency = (value) => ({
  type: Types.SET_CURRENCY,
  payload: value
})

export const currencySetFcfa = (value) => ({
  type: Types.SET_FCFA,
  payload: value
})

export const currencySetGhs = (value) => ({
  type: Types.SET_GHS,
  payload: value
})

export const currencySetNgn = (value) => ({
  type: Types.SET_NGN,
  payload: value
})

export const currencyResetFcfa = () => ({ type: Types.RESET_FCFA })
export const currencyResetGhs = () => ({ type: Types.RESET_GHS })
export const currencyResetNgn = () => ({ type: Types.RESET_NGN })

export const compAddFormula = (formula) => ({
  type: Types.COMP_ADD_FORMULA,
  payload: formula
})

export const compRmFormula = (index) => ({
  type: Types.COMP_RM_FORMULA,
  payload: index
})

export const compRmIndex = (index) => ({
  type: Types.COMP_RM_INDEX,
  payload: index
})

export const compUpdateProducts = (index, products) => ({
  type: Types.COMP_UPDATE_PRODUCTS,
  payload: { index, products }
})

export const compUpdateBlending = (index, blending) => ({
  type: Types.COMP_UPDATE_BLENDING,
  payload: { index, blending }
})

export const compUpdateDestination = (index, destination) => ({
  type: Types.COMP_UPDATE_DESTINATION,
  payload: { index, destination }
})

export const compUpdatePorts = (index, portOfEntry) => ({
  type: Types.COMP_UPDATE_PORTS,
  payload: { index, portOfEntry }
})

export const compEditFormula = (index) => ({
  type: Types.COMP_EDIT_FORMULA,
  payload: index
})

export const compClear = () => ({
  type: Types.COMP_CLEAR
})

export const setMenuHeader = (value) => ({
  type: Types.SET_MENUSELECTED,
  payload: value
})
