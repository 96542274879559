import React from 'react';

import { InputNumber } from 'primereact/inputnumber';

import Styles from './index.module.scss';
import { Bold } from '../../../CommonStyles.module.scss';

export function RatePanel({ onChange, mode, costPerHectare, actualFormula }) {

  const [n, setN] = React.useState(0)
  const [p2O5, setP2O5] = React.useState(0)
  const [k20, setK20] = React.useState(0)
  const [mgO, setMgO] = React.useState(0)
  const [fe, setFe] = React.useState(0)
  const [mn, setMn] = React.useState(0)
  const [zn, setZn] = React.useState(0)
  const [b, setB] = React.useState(0)
  const [cu, setCu] = React.useState(0)
  const [ca, setCa] = React.useState(0)
  const [s, setS] = React.useState(0)

  function compileData() {
    return { n, p2O5, mgO, fe, mn, zn, b, cu, ca, s }
  }

  React.useEffect(() => void onChange && onChange(compileData), Object.values(compileData()))

  function setter(setter) {
    return ({ value }) => setter(value)
  }

  function createInput(label, value, setValue) {
    return (
      <div>
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon">{label}</span>
          <InputNumber
            min={0}
            suffix={mode === 1 ? " Kg/ha" : ''}
            value={value}
            onChange={setter(setValue)}
            className={mode === 1 ? Styles.Large : Styles.Small}
          />
        </div>
      </div>
    )
  }

  function createOutputTable(entries) {
    return (
      <div className={`p-grid ${Styles.ActualFormula}`}>
        <div className="p-col" style={{ textAlign: 'right', flexGrow: 0 }}>
    { Object.keys(entries).map(label => <div>{label}:</div>) }
        </div>
        <div className="p-col">
    { Object.values(entries).map(value => <div>{value.toFixed(2).padStart(5, '0')} Kg/ha</div>) }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="p-grid">
        <div className={`p-col ${Styles.Form}`} style={{ flexGrow: 1 }}>
          <span className={Bold}>Requested {mode === 1 ? 'Rate' : 'Formula'}</span>
          {
            [
              ['N', n, setN],
              ['P2O5', p2O5, setP2O5],
              ['K20', k20, setK20],
              ['MgO', mgO, setMgO],
              ['Fe', fe, setFe],
              ['Mn', mn, setMn],
              ['Zn', zn, setZn],
              ['B', b, setB],
              ['Cu', cu, setCu],
              ['Ca', ca, setCa],
              ['S', s, setS]
            ].map(([label, value, setValue]) => createInput(label, value, setValue))
          }
        </div>
        <div className="p-col"  style={{ flexGrow: 4 }}>
          <span className={Bold}>Actual Formula</span>
          {
            createOutputTable(Object.fromEntries([
              ['N', actualFormula.n],
              ['P2O5', actualFormula.p2O5],
              ['K20', actualFormula.k20],
              ['MgO', actualFormula.mgO],
              ['Fe', actualFormula.fe],
              ['Mn', actualFormula.mn],
              ['Zn', actualFormula.zn],
              ['B', actualFormula.b],
              ['Cu', actualFormula.cu],
              ['Ca', actualFormula.ca],
              ['S', actualFormula.s]
            ]))
          }
        </div>
      </div>
      {
        mode === 1 && <span>Cost per hectare: ${costPerHectare || 0}</span>
      }
    </>
  )
}
