import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Select Formulas": "Select Formulas",
      "Share and Print": "Share and Print",
      "Share": "Share",
      "Print": "Print",
      "Filter and Show Details" : "Filter and Show Details",
      "Filter": "Filter",
      "Details": "Details",
      "Pick the formulas": "Pick the formulas",
      "Use the": "Use the",
      "Formulas to Compare": "Formulas to Compare",
      "OK": "OK",
      "Cancel": "Cancel",
      "Choose your preferences below": "Choose your preferences below",
      "This is the space for you to compare the formulas created and evaluate the best option.": "This is the space for you to compare the formulas created and evaluate the best option.",
      "No Formulas are being compared": "No Formulas are being compared",
      "Please select a final destination": "Please select a final destination",
      "Please select a place of bagging or blending": "Please select a place of bagging or blending",
      "Please select a port of entry": "Please select a port of entry",
      "Please select at least one formula": "Please select at least one formula",
      "Port of Entry": "Port of Entry",
      "Select the Port of Entry": "Select the Port of Entry",
      "Place of Bagging or Blending": "Place of Bagging or Blending",
      "Select place of Processing": "Select place of Processing",
      "Destination": "Destination",
      "Select the Final Destination": "Select the Final Destination"
    }
  },
  fr: {
    translation: {
      "Select Formulas": "Sélectionnez les formules",
      "Share and Print": "Partager et Imprimer",
      "Share": "Partager",
      "Print": "Imprimer",
      "Filter and Show Details" : "Filtrer et afficher les détails",
      "Filter": "Filtrer",
      "Details": "Détails",
      "Pick the formulas": "Choisissez les formules",
      "Use the": "Utiliser le",
      "Formulas to Compare": "Formules à comparer",
      "OK": "OK",
      "Cancel": "Annuler",
      "Choose your preferences below": "Choisissez vos préférences ci-dessous",
      "This is the space for you to compare the formulas created and evaluate the best option.": "C'est l'espace où vous pouvez comparer les formules créées et évaluer la meilleure option.",
      "No Formulas are being compared": "Aucune formule n'est comparée",
      "Please select a final destination": "Veuillez sélectionner une destination finale",
      "Please select a place of bagging or blending": "Veuillez sélectionner un lieu d'ensachage ou de mélange",
      "Please select a port of entry": "Veuillez sélectionner un port d'entrée",
      "Please select at least one formula": "Veuillez sélectionner au moins une formule",
      "Port of Entry": "Port d'entrée",
      "Select the Port of Entry": "Sélectionnez le port d'entrée",
      "Place of Bagging or Blending": "Lieu d'ensachage ou de mélange",
      "Select place of Processing": "Lieu d'ensachage ou de mélange",
      "Destination": "Destination",
      "Select the Final Destination": "Sélectionner la destination finale"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;