import React, { useState, useEffect, createRef } from 'react';
import * as Actions from '../../redux/actions';
import { useDispatch } from 'react-redux';
import ArrowDownIcon from '../../assets/images/ArrowDownIcon.png'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function SelectCurrency({ myId, inputName, currenciesList, setCurrencyName }) {

  const [fcfa, setFcfa] = useState(false)
  const [ghs, setGhs] = useState(false)
  const [ngn, setNgn] = useState(false)
  const [isMobile, setIsMobile] = useState()
  const [isCurrencyShowing, setIsCurrencyShowing] = useState(false)

  const myDropdown = createRef();
  const myInput = createRef();
  const currencyName = createRef();
  const [query, setQuery] = useState([]);
  const dispatch = useDispatch()

  useEffect(() => {
    if (window.matchMedia("(min-width:900px)").matches) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  })

  function setDropdownValue(currency, inputRef, currencyNameRef) {
    if (currency != 'No results found') {
      inputRef.current.value = currency.value;
      currencyNameRef.current.innerHTML = currency.name;
    }

    cookies.set('currency', currency.name, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })

    if (currencyNameRef.current.innerHTML == "FCFA") {
      setCurrencyName("FCFA")
      dispatch(Actions.setCurrency('FCFA'))
      return setFcfa(true), setGhs(false), setNgn(false)
    }
    else if (currencyNameRef.current.innerHTML == "GHS") {
      setCurrencyName("GHS")
      dispatch(Actions.setCurrency('GHS'))
      return setGhs(true), setFcfa(false), setNgn(false)
    }
    else if (currencyNameRef.current.innerHTML == "NGN") {
      setCurrencyName("NGN")
      dispatch(Actions.setCurrency('NGN'))

      return setGhs(false), setNgn(true), setFcfa(false)
    } else {
      setCurrencyName("USD")
      setGhs(false)
      setNgn(false)
      setFcfa(false)
      cookies.set('USD', currency.value, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })

      return dispatch(Actions.setCurrency('USD'))
    }
  }

  function dropdown(p, elementRef) {
    var d = ["block", "none"];
    elementRef.current.style.display = d[p];
  }

  useEffect(() => {
    setQuery(currenciesList);
  }, [currenciesList]);

  useEffect(() => {
    const currency = cookies.get('currency') === undefined ? currenciesList[0] : currenciesList.find(e => e.name === cookies.get('currency'))
    setDropdownValue(currency, myInput, currencyName);
  }, []);

  function setCurrency(e) {
    const value = parseFloat(e)
    if (fcfa == true) {
      cookies.set('FCFA', isNaN(value) ? 0 : value, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })
      dispatch(Actions.currencySetFcfa(isNaN(value) ? 0 : value))
    }
    else if (ghs == true) {
      cookies.set('GHS', isNaN(value) ? 0 : value, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })
      dispatch(Actions.currencySetGhs(isNaN(value) ? 0 : value))
    }
    else if (ngn == true) {
      cookies.set('NGN', isNaN(value) ? 0 : value, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })
      dispatch(Actions.currencySetNgn(isNaN(value) ? 0 : value))
    }
    else {
      cookies.set('USD', isNaN(value) ? 0 : value, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })
    }
  }

  return (
    <>
      <button style={{ margin: "3px 0 0 -10px", cursor: 'pointer' }} onFocus={() => dropdown(0, myDropdown)} onClick={() => setIsCurrencyShowing(!isCurrencyShowing)} >
        {isCurrencyShowing ? <img style={{ transform: "rotate(-180deg)", maxWidth: "22px" }} src={ArrowDownIcon} alt="" /> : <img style={{ maxWidth: "22px" }} src={ArrowDownIcon} alt="" />}
      </button>

      <div className={isCurrencyShowing === true ? "dropdownSelectCurrency" : "hidden"}>
        <input
          ref={myInput}
          id={myId}
          autoComplete="off"
          spellCheck="false"
          className="dropdownInputCurrency"
          type="text"
          name={inputName}
          onChange={e => setCurrency(e.target.value)}
        />

        <div className="selectLanguage">
          <button ref={currencyName} ></button>
        </div>

        <div className="dropDownCurrency" ref={myDropdown}>
          <div className="listDropDownCurrency">
            {query && query.map((value, index) => (
              <div key={index}>
                <div className="itemCurrency" onMouseDown={() => setDropdownValue(value, myInput, currencyName)}>{value.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
