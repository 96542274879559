import React from 'react';
import { ApiContext } from '../../../../providers/ApiProvider';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';

import { Dialog } from 'primereact/dialog';
import GenericMaintenanceScreen from './GenericMaintanceScreen';
import FloatLabel from '../../../utils/FloatLabel';
import useTowns from '../../../../hooks/useTowns';
import TownSelector from '../../../utils/selectors/TownSelector';
import useErrorHandler from '../../../../hooks/useErrorHandler';

import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
import XIcon from '../../../../assets/images/XIcon.svg'

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext)
  const notification = React.useContext(NotificationContext)
  const errorHandler = useErrorHandler('Something went wrong on managing the data')
  const [loading, setLoading] = React.useState(false)
  const [triedOnce, setTriedOnce] = React.useState(false)

  const [statisticsDuty, setStatisticsDuty] = React.useState(0)
  const [solidarityLevy, setSolidarityLevy] = React.useState(null)
  const [ccvrLevy, setCcvrLevy] = React.useState(null)
  const [eximLevy, setEximLevy] = React.useState(null)
  const [townId, setTownId] = React.useState(null)

  const [town] = useTowns(townId)

  React.useEffect(refresh, [id])

  function refresh() {
    setTriedOnce(false)
    setLoading(true)
    if (id) {
      api.getFinalDestination(id)
        .then(({ data }) => {
          setStatisticsDuty(data.statisticDuty)
          setSolidarityLevy(data.solidarityLevy)
          setCcvrLevy(data.ccvrLevy)
          setEximLevy(data.eximLevy)
          setLoading(false)
          setTownId(data.town?.id)
        }).catch((e) => {
          console.error(e)
          toast.error('Something went wrong on fetching the data', e.message)
        })
    } else {
      setStatisticsDuty(0)
      setSolidarityLevy(null)
      setCcvrLevy(null)
      setEximLevy(null)
      setLoading(null)
      setTownId(null)
    }
  }

  function finish() {
    toast.success('Destination saved successfully', town?.name)
    onHide()
    notify()
  }

  function send() {
    setTriedOnce(true)
    if (town && typeof statisticsDuty === 'number') {
      const payload = { statisticsDuty, solidarityLevy, ccvrLevy, eximLevy, town: town }
      setLoading(true)
      if (id) {
        api.editFinalDestination(id, payload)
          .then(finish).catch((e) => {
            console.error(e)
            notification.sendError('Something went wrong on saving the data', e.message)
          })
      } else {
        api.addFinalDestination(payload)
          .then(finish).catch((e) => {
            console.error(e)
            notification.sendError('Something went wrong on saving the data', e.message)
          })
      }
    }
  }

  function deletedToast() {
    toast.success('Country deleted successfully', town?.name)
    onHide()
    notify()
  }

  function handleDelete({ id, t }) {
    api.deleteFinalDestination(id).then(deletedToast).catch(errorHandler)
    toast.dismiss(t.id)
  }

  function askDelete() {
    toast((t) => (
      <div className="deleteToastAdm">
        <h1> Are you sure you want to delete <b>{town?.name}</b>?</h1>
        <p>This is an one-way action!</p>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <button className="btn btn--secondary" onClick={() => toast.dismiss(t.id)}>
            Cancel
          </button>
          <button style={{ marginLeft: '5px' }} className="btn btn--alert center" onClick={() => handleDelete({ id, t })}>
            Delete
          </button>
        </div>
      </div>
    ))
  }

  const header =
    <>
      <div style={{ position: 'relative' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{margin: '0'}}>{id ? `Editing ${town?.name}` : 'Creating'}</h1>
          <button className="xIconButton center" onClick={onHide}>
            <img src={XIcon} alt="" />
          </button>
        </div>
        <p>{id ? `Select the options below to edit the Destination ` : 'Select the options below to create the Destination'}</p>
      </div>
    </>

  const body = <>
    <div className="firstFloatLabel32">
      <label className="selectLabelAdm" htmlFor="town">Town:</label><br />
      <TownSelector
        id="town"
        value={townId}
        onChange={setTownId}
        disableNull
      />
    </div>

    <div className="firstFloatLabel32">
      <FloatLabel id="statistics" label="Statistics Duty">
        <InputNumber
          value={statisticsDuty}
          suffix="%"
          mode="decimal"
          minFractionDigits={2}
          maxFractionDigits={3}
          onValueChange={(e) => setStatisticsDuty(e.value)}
        />
      </FloatLabel>
    </div>

    <div className="firstFloatLabel32">
      <FloatLabel id="solidarity" label="Solidarity Levy">
        <InputNumber
          value={solidarityLevy}
          suffix="%"
          mode="decimal"
          minFractionDigits={2}
          maxFractionDigits={3}
          onValueChange={(e) => setSolidarityLevy(e.value)}
        />
      </FloatLabel>
    </div>

    <div className="firstFloatLabel32">
      <FloatLabel id="ccvr" label="CCVR Levy">
        <InputNumber
          value={ccvrLevy}
          suffix="%"
          mode="decimal"
          minFractionDigits={2}
          maxFractionDigits={3}
          onValueChange={(e) => setCcvrLevy(e.value)}
        />
      </FloatLabel>
    </div>

    <FloatLabel id="exim" label="Exim Levy">
      <InputNumber
        value={eximLevy}
        suffix="%"
        mode="decimal"
        minFractionDigits={2}
        maxFractionDigits={3}
        onValueChange={(e) => setEximLevy(e.value)}
      />
    </FloatLabel>
  </>


  const footer =
    <>
      {id && !loading ? (
        <>
          <div style={{ width: '100%', display: 'flex' }}>
            <button className="btn btn--alert center" onClick={askDelete}>Delete</button>
            <button className="btn btn--primary" onClick={send}>Save</button>
          </div>
        </>
      ) :
        <>
          {!loading && <button className="btn btn--primary" onClick={send}>Create</button>}
        </>
      }
    </>

  return (
    <Dialog
      className="modalAdmWrapper"
      onHide={onHide}
      visible={visible}
      header={header}
      footer={footer}
      draggable={false}
    >
      {loading ?
        <div className="dfCenter">
          <ReactLoading type={'spinningBubbles'} color={'#31c5f4'} height={'40px'} width={'40px'} />
        </div> : body}
    </Dialog>
  )
}

export default function FinalDestinationMaintenance() {

  const api = React.useContext(ApiContext)

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  )

  function filterData (data, filterTable) {
    const dataFiltered = filterTable.length > 0 ? data.filter(e =>
      (e.town &&
        e.town.name
          .toLowerCase()
          .replace(/[\s-]/g, '')
          .includes(filterTable.replace(/[\s-]/g, '').toLowerCase())) ||
      (e.town.country &&
        e.town.country.displayName
          .toLowerCase()
          .replace(/[\s-]/g, '')
          .includes(filterTable.replace(/[\s-]/g, '').toLowerCase())) ||
      (e.town.country &&
        e.town.country.alphaCode
          .toLowerCase()
          .replace(/[\s-]/g, '')
          .includes(filterTable.replace(/[\s-]/g, '').toLowerCase()))
    ) : data

    return dataFiltered
  }

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getFinalDestinations}
      renderEditDialog={dialog}
      filterData={filterData}
    >
      <Column field="id" header="Id" sortable />
      <Column field="statisticDuty" header="Statistic Duty" sortable />
      <Column field="solidarityLevy" header="Solidarity Levy" sortable />
      <Column field="ccvrLevy" header="CCVR Levy" sortable />
      <Column field="eximLevy" header="Exim Levy" sortable />
      <Column
        field="town.name"
        header="Town"
        sortable
      />
      <Column
        field="town.country.displayName"
        header="Country"
        body={({ town }) => `${town.country.displayName} (${town.country.alphaCode})`}
        sortable
      />
    </GenericMaintenanceScreen>
  )
}
