import { Types } from '../actions/';

const defaultState = Object.freeze({
  menuSelected: '/my/home',
})

export default (state = defaultState, action) => {
  switch (action.type) {

    case Types.SET_MENUSELECTED: return {
      ...state,
      menuSelected: action.payload
    }

    /*case Types.RESET_MENUSELECTED: return {
      ...state,
      menuSelected: defaultState.menuSelected
    }*/

    default: return state
  }
}
