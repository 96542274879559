import { mkPair } from './minmax'

export const SEA_FREIGHT_INSURRANCE = mkPair(0.007, 0.008)
export const FORWARDER_FEES = mkPair(0.0025, 0.0035)

export const ECOWAS_AU = 0.0050
export const GARANTY_FOUND = 0.50
export const OTHER_SMALL_TRANSIT_FEES = 0.10
export const PORT_TO_WAREHOUSE = mkPair(4, 6)
export const COST_PROCESS = mkPair(10, 15)
export const FACTORY_DEPRECIATION = mkPair(2.5, 5)

export const IMPORTER_BANK_FEES = mkPair(0.15, 0.25)
export const IMPORTER_HANDLING_FEES = mkPair(3, 5)
export const IMPORTER_ADMINISTRATIVE_FEES = mkPair(1.5, 2)
export const IMPORTER_INSURRANCE = mkPair(0.3, 0.4)
export const IMPORTER_LOSS_OF_WEIGHT = mkPair(0.5, 1)
export const IMPORTER_RAW_MARGIN = mkPair(20, 30)
export const IMPORTER_TAXES_ON_MARGIN = 25

export const CUSTOM_LAND_BORDER = 0.5
export const FORWARDER_LAND_BORDER = mkPair(1, 2)

export const WHOLESALER_STORAGE_FEES = mkPair(1.5, 3)
export const WHOLESALER_HANDLING_FEES = mkPair(1, 1.5)
export const WHOLESALER_LOSS_OF_WEIGHT = mkPair(0.2, 0.3)
export const WHOLESALER_RAW_MARGIN = mkPair(10, 20)
export const WHOLESALER_TAXES_ON_MARGIN = 5
