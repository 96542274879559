import React from "react";
import { useProductTypes } from "../../../../hooks";
import { useState } from 'react';
import InfoIcon from '../../../../assets/images/infoIcon.svg'
import XIcon from '../../../../assets/images/XIcon.svg'
import { Droppable, Draggable } from 'react-beautiful-dnd'

export default function ListOfIngredients() {
	const productTypes = useProductTypes();

	const fillers = productTypes.filter(e => e.isFiller);
	const notFiller = productTypes.filter(e => !e.isFiller);

	const [isShowing, setIsShowing] = useState(false);
	const [checkedBlend, setCheckedBlend] = useState(true);
	const [checkedCompound, setCheckedCompound] = useState(true);
	const [checkedStraight, setCheckedStraight] = useState(true);
	const [checkedFiller, setCheckedFiller] = useState(true);
	const [isHovering, setIsHovering] = useState(false);

	const handleChangeBlend = () => {
		setCheckedBlend(!checkedBlend);
	};

	const handleChangeCompound = () => {
		setCheckedCompound(!checkedCompound);
	};

	const handleChangeStraight = () => {
		setCheckedStraight(!checkedStraight);
	};

	const handleChangeFiller = () => {
		setCheckedFiller(!checkedFiller);
	};

	const Checkbox = ({ label, value, onChange }) => {
		return (
			<label>
				<input type="checkbox" checked={value} onChange={onChange} />
				{label}
			</label>
		);
	};

	return (
		<>
			<div className="IngredientsHeader">
				<div className="IngredientsHeaderStart"><p id="info">List of Ingredients</p>
					<div>
						<img
							src={InfoIcon}
							onMouseEnter={() => setIsHovering(true)}
							onMouseLeave={() => setIsHovering(false)}
							alt="" />
					</div>
				</div>

				<div
					className={`ingredientsHeaderLegend ${isHovering ? "" : "hidden"}`}>
					<div>
						<div>
							<div className="Blend"></div>
							<p>Blend</p>
						</div>
						<div>
							<div className="Compound"></div>
							<p>Compound</p>
						</div>
						<div>
							<div className="Straight"></div>
							<p>Straight</p>
						</div>
						<div>
							<div className="Filler"></div>
							<p>Filler</p>
						</div>
					</div>
				</div>

				{isShowing ?
					<div className="ingredientsFilter">
						<div className="labelFilter">
							<Checkbox
								label="Blend"
								value={checkedBlend}
								onChange={handleChangeBlend}
							/>
						</div>
						<div className="labelFilter">
							<Checkbox
								label="Compound"
								value={checkedCompound}
								onChange={handleChangeCompound}
							/>
						</div>

						<div className="labelFilter">
							<Checkbox
								label="Straight"
								value={checkedStraight}
								onChange={handleChangeStraight}
							/>
						</div>

						<div className="labelFilter">
							<Checkbox
								label="Filler"
								value={checkedFiller}
								onChange={handleChangeFiller}
							/>
						</div>
						<button onClick={() => setIsShowing(!isShowing)}>
							<img src={XIcon} alt="X" />
						</button>
					</div> : null}

				<button onClick={() => setIsShowing(!isShowing)}>
					<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18 11" fill="none">
						<path d="M3.3 4.4H14.3V6.6H3.3V4.4ZM0 0H17.6V2.2H0V0ZM6.6 8.8H11V11H6.6V8.8Z" />
					</svg>
				</button>
			</div>
			<Droppable droppableId="ingredients">
				{(provided) => (
					<ul className="ingredientsMain" {...provided.droppableProps} ref={provided.innerRef}>
						{notFiller.map((product, index) => {
							return (
								<Draggable key={product.name} draggableId={product.name} index={index} >
									{(provided) => (
										<li className="ingredientsGroup" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
											<div className="ingredients--card blend">
												<p>{product.name}</p>
												<svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 114 42" fill="none">
													<rect width="114" height="14" rx="1" fill="white" />
													<rect y="27" width="114" height="15" rx="1" fill="white" />
												</svg>
											</div>
										</li>
									)}
								</Draggable>
							);
						})}
						{checkedFiller && fillers.map((product, index) => {
							return (
								<Draggable key={product.name} draggableId={product.name} index={index}>
									{(provided) => (
										<li className="ingredientsGroup" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
											<div className="ingredients--card filler" draggable="true">
												<p>{product.name}</p>
												<svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 114 42" fill="none">
													<rect width="114" height="14" rx="1" fill="white" />
													<rect y="27" width="114" height="15" rx="1" fill="white" />
												</svg>
											</div>
										</li>
									)}
								</Draggable>
							);
						})}
						{provided.placeholder}
					</ul>
				)}
			</Droppable>

		</>
	)
}